/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AddAlternativeNameRequest */
export interface AddAlternativeNameRequest {
  /**
   * Alternative Name
   * @minLength 3
   */
  alternative_name: string;
}

/** AddHiddenCountryCodeRequest */
export interface AddHiddenCountryCodeRequest {
  /**
   * Country Code
   * @minLength 2
   * @maxLength 2
   */
  country_code: string;
}

/** AddHiddenRelationRequest */
export interface AddHiddenRelationRequest {
  /** Relation Artkey */
  relation_artkey: number;
}

/** AdditionalSalesOrderDetailsResponse */
export interface AdditionalSalesOrderDetailsResponse {
  /** Vat Code */
  vat_code: number;
  supplier: Fact2ServerApiPortalOrderAdditionalDetailsAdditionalSalesOrderDetailsResponseSupplier;
  was_created_by: PortalUser | null;
  destination: Destination | null;
}

/** AfvalfondsRequest */
export interface AfvalfondsRequest {
  /** Year */
  year: number;
}

/** AlcoholFormatting */
export enum AlcoholFormatting {
  Percentage = "percentage",
  Decimal = "decimal",
}

/** AlcoholStatistic */
export interface AlcoholStatistic {
  /** Alcohol Percentage */
  alcohol_percentage: number;
  /** Count */
  count: number;
}

/** AlternateRelationResponse */
export interface AlternateRelationResponse {
  /** Alternate Relation Artkey */
  alternate_relation_artkey: number | null;
}

/** AuditLog */
export interface AuditLog {
  /** Artkey */
  artkey: number;
  type: AuditLogType;
  /** Message */
  message: string;
}

/** AuditLogType */
export enum AuditLogType {
  Matched = "Matched",
  MatchedExactProductMap = "Matched exact product map",
  NoProductsMatchedEnough = "No products matched enough",
  NoMatchOnProduct = "No match on product",
  NoBottleFound = "No bottle found",
  NoMappingFound = "No mapping found",
  NoSourceLineFound = "No source line found",
  NoProductNameFound = "No product name found",
  ParsingError = "Parsing error",
  UnhandledException = "Unhandled exception",
  BottleCreated = "Bottle created",
  ProductCreated = "Product created",
  ProductMapCreated = "ProductMap created",
  ProductMapUpdated = "ProductMap updated",
  Suppressed = "Suppressed",
  GTINCreated = "GTIN created",
  GTINNotFound = "GTIN not found",
  GTINDifferentBottle = "GTIN different bottle",
  GTINDifferentProduct = "GTIN different product",
}

/** AvailabilityStatistic */
export interface AvailabilityStatistic {
  offer_item_type: AvailabilityType;
  /** Count */
  count: number;
}

/** AvailabilityType */
export enum AvailabilityType {
  Stock = "stock",
  Tbo = "tbo",
  Purchase = "purchase",
}

/** Bottle */
export interface Bottle {
  product: Product;
  /** Volume */
  volume: string;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  /** Refill Status */
  refill_status: string;
}

/** BottleByGTINResp */
export interface BottleByGTINResp {
  /** Artkey */
  artkey: number;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  /** Default Country Code */
  default_country_code: string | null;
  /** Product Category Artkey */
  product_category_artkey: number;
  /** Product Category Name */
  product_category_name: string;
  /** Product Artkey */
  product_artkey: number;
  /** Product Name */
  product_name: string;
  /** Refill Status */
  refill_status: string;
  /** Volume */
  volume: string;
}

/** BottleData */
export interface BottleData {
  /** Artkey */
  artkey?: number | null;
  /** Alcohol Percentage */
  alcohol_percentage?: number | string | null;
  /** Volume */
  volume?: number | string | null;
  refill_status?: RefillStatus | null;
  product: ProductData;
}

/** BottleThroughputResponse */
export interface BottleThroughputResponse {
  /** Last Month */
  last_month: number;
  /** Last Quarter */
  last_quarter: number;
  /** Last Year */
  last_year: number;
}

/** BrandCollectionResponse */
export interface BrandCollectionResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Match Text */
  match_text: string;
  /** Brand Holder Name */
  brand_holder_name: string;
}

/** BuyFromAccountSalesOrder */
export interface BuyFromAccountSalesOrder {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  /** Is Invoiced On */
  is_invoiced_on: string | null;
  account: Fact2ServerApiDiscoverPurchaseOrderPurchaseOrderGetPurchaseOrderResponseBuyFromAccountSalesOrderAccount;
}

/** CalculationDetails */
export interface CalculationDetails {
  /** Number Of Cases */
  number_of_cases: number;
  /** Number Of Lines */
  number_of_lines: number;
  /** Incoterm */
  incoterm: string;
  /** Target Is Loendersloot */
  target_is_loendersloot: boolean;
  /** Is Loendersloot Calculation */
  is_loendersloot_calculation: boolean;
  /** Euro Was Bought For */
  euro_was_bought_for: string;
  /** Has Waste Fund Costs Nl */
  has_waste_fund_costs_nl: boolean;
  /** Warehouse Costs Per Case */
  warehouse_costs_per_case: string | null;
  /** Diesel Surcharge Percentage */
  diesel_surcharge_percentage: string;
  /** Additional Per Case */
  additional_per_case: string | null;
  /** Exw Handover Costs */
  exw_handover_costs: string | null;
  /** Spirits Costs Per Case */
  spirits_costs_per_case: string | null;
  /** Spirits Maximum Cases */
  spirits_maximum_cases: number | null;
  /** Spirits Maximum Costs */
  spirits_maximum_costs: string | null;
  /** Spirits Minimum Cases */
  spirits_minimum_cases: number | null;
  /** Spirits Minimum Costs */
  spirits_minimum_costs: string | null;
  /** Inspection Costs First Item */
  inspection_costs_first_item: string | null;
  /** Inspection Costs Per Item */
  inspection_costs_per_item: string | null;
  /** Has Duty Drawback */
  has_duty_drawback: boolean | null;
  /** Duty Drawback */
  duty_drawback: string | null;
  /** Duty Drawback Per Case */
  duty_drawback_per_case: string | null;
  /** Non Spirits Minimum Pallets */
  non_spirits_minimum_pallets: number | null;
  /** Non Spirits Minimum Costs */
  non_spirits_minimum_costs: string | null;
  /** Non Spirits Costs Per Pallet */
  non_spirits_costs_per_pallet: string | null;
}

/** CaseAndSupplierResponse */
export interface CaseAndSupplierResponse {
  supplier?: Fact2ServerApiDiscoverExploreCaseAndSupplierResponseSupplier | null;
  case?: Fact2ServerApiDiscoverExploreCaseAndSupplierResponseCase | null;
}

/** CaseUpdateRequest */
export interface CaseUpdateRequest {
  /** Cases Per Pallet */
  cases_per_pallet: number | null;
  /** Cases Per Pallet Layer */
  cases_per_pallet_layer: number | null;
}

/** CategoriesResponse */
export interface CategoriesResponse {
  /** Categories */
  categories: Fact2ServerApiPortalMenuCategoriesResponseCategory[];
}

/** CategoryStatistic */
export interface CategoryStatistic {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Count */
  count: number;
}

/** ChangePasswordRequest */
export interface ChangePasswordRequest {
  /** New Password */
  new_password: string;
  /** Current Password */
  current_password: string;
}

/** CollectionLoenderslootMessageResponse */
export interface CollectionLoenderslootMessageResponse {
  /** Artkey */
  artkey: number;
  /** Status */
  status: string;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Message Type */
  message_type: string;
  /** Action Type */
  action_type?: string | null;
  /** Header Type */
  header_type?: string | null;
  /** Shipment Id */
  shipment_id?: string | null;
}

/**
 * CollectionViewMeta
 * Description of the CollectionView (sort and filters) as served by the backend.
 */
export interface CollectionViewMeta {
  sort_definition: SortDefinition;
  /** Filter Definitions */
  filter_definitions: FilterDefinition[];
}

/** CollectionViewResult[GetCandidatesCollectionResponse] */
export interface CollectionViewResultGetCandidatesCollectionResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetCandidatesCollectionResponse[];
}

/** CollectionViewResult[GetItemCollectionViewResponse] */
export interface CollectionViewResultGetItemCollectionViewResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetItemCollectionViewResponse[];
}

/** CollectionViewResult[GetOfferItemCompareWithMarketResponse] */
export interface CollectionViewResultGetOfferItemCompareWithMarketResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetOfferItemCompareWithMarketResponse[];
}

/** CollectionViewResult[GetOfferItemsCollectionViewResponse] */
export interface CollectionViewResultGetOfferItemsCollectionViewResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetOfferItemsCollectionViewResponse[];
}

/** CollectionViewResult[GetProductCategoryCollectionResponse] */
export interface CollectionViewResultGetProductCategoryCollectionResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetProductCategoryCollectionResponse[];
}

/** CollectionViewResult[GetPurchaseOrderItemsCollectionViewResponse] */
export interface CollectionViewResultGetPurchaseOrderItemsCollectionViewResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetPurchaseOrderItemsCollectionViewResponse[];
}

/** CollectionViewResult[GetRelationPurchaseOrderResponse] */
export interface CollectionViewResultGetRelationPurchaseOrderResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetRelationPurchaseOrderResponse[];
}

/** CollectionViewResult[GetSalesOrdersCollectionViewResponse] */
export interface CollectionViewResultGetSalesOrdersCollectionViewResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetSalesOrdersCollectionViewResponse[];
}

/** CollectionViewResult[GetSupplierPriceListItemCollectionResponse] */
export interface CollectionViewResultGetSupplierPriceListItemCollectionResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetSupplierPriceListItemCollectionResponse[];
}

/** CollectionViewResult[GetUnresolvedItemsResponse] */
export interface CollectionViewResultGetUnresolvedItemsResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetUnresolvedItemsResponse[];
}

/** CollectionViewResult[GetVoucherRelationCollectionViewResult] */
export interface CollectionViewResultGetVoucherRelationCollectionViewResult {
  /** Total Count */
  total_count: number;
  /** Items */
  items: GetVoucherRelationCollectionViewResult[];
}

/** CollectionViewResult[SalesOrderItemByBottle] */
export interface CollectionViewResultSalesOrderItemByBottle {
  /** Total Count */
  total_count: number;
  /** Items */
  items: SalesOrderItemByBottle[];
}

/** CollectionViewResult[SupplierPriceListCollectionResponse] */
export interface CollectionViewResultSupplierPriceListCollectionResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: SupplierPriceListCollectionResponse[];
}

/** CollectionViewResult[SuppressionListResponse] */
export interface CollectionViewResultSuppressionListResponse {
  /** Total Count */
  total_count: number;
  /** Items */
  items: SuppressionListResponse[];
}

/** CompetitorPrice */
export interface CompetitorPrice {
  /** Supplier Price List Artkey */
  supplier_price_list_artkey: number;
  /** Price Per Case */
  price_per_case: string;
  /** Delta */
  delta: string;
  /** Relation Artkey */
  relation_artkey: number;
  /** Relation Name */
  relation_name: string;
}

/** ContactPerson */
export interface ContactPerson {
  /** Name */
  name: string;
}

/** CostType */
export enum CostType {
  LOENDERSLOOT = "LOENDERSLOOT",
  WAREHOUSE_BASE_COSTS = "WAREHOUSE_BASE_COSTS",
}

/** CountriesOfOriginRequest */
export interface CountriesOfOriginRequest {
  /** Offer Item Artkeys */
  offer_item_artkeys: number[];
}

/** CountriesOfOriginResponse */
export interface CountriesOfOriginResponse {
  /** Artkey */
  artkey: number;
  /** Country Of Origin */
  country_of_origin: string;
  /** Number Of Cases Available */
  number_of_cases_available: number;
}

/** CreateEntityResponse */
export interface CreateEntityResponse {
  /** Artkey */
  artkey: number;
}

/** CreateIssueRequest */
export interface CreateIssueRequest {
  /** Description */
  description: string;
  media: Media;
  /** Title */
  title: string;
  /** Url */
  url: string;
  /** Sentry Replay Id */
  sentry_replay_id: string;
}

/** CreateIssueResponse */
export interface CreateIssueResponse {
  /** Web Url */
  web_url: string;
  /** Detail */
  detail: string;
}

/** CreateLoenderslootTagRequest */
export interface CreateLoenderslootTagRequest {
  /** Code */
  code: string;
  /** Description */
  description: string;
  /** Unapprove When Added */
  unapprove_when_added: boolean;
  /** Show As Feature */
  show_as_feature: boolean;
  /** Is Portal Filter */
  is_portal_filter: boolean;
  /** Forbid In Stock */
  forbid_in_stock: boolean;
  /** Item Tag Artkey */
  item_tag_artkey: number;
}

/** CreateOrUpdateItemTagRequest */
export interface CreateOrUpdateItemTagRequest {
  /** Name */
  name: string;
  /** Abbreviation */
  abbreviation: string;
  /** Item Tag Category Artkey */
  item_tag_category_artkey: number;
}

/** CreateOrUpdateOrderRatingRequest */
export interface CreateOrUpdateOrderRatingRequest {
  /**
   * Rating
   * @exclusiveMin 0
   * @max 10
   */
  rating: number;
  /** Comment */
  comment?: string | null;
}

/** CreateOrUpdateProductCategoryRequest */
export interface CreateOrUpdateProductCategoryRequest {
  /** Name */
  name: string;
  /** Parent Artkey */
  parent_artkey: number | null;
  /** Priority */
  priority: number | null;
  /** Be Vat Rate Artkey */
  be_vat_rate_artkey: number;
  /** Nl Vat Rate Artkey */
  nl_vat_rate_artkey: number;
  /** De Vat Rate Artkey */
  de_vat_rate_artkey: number;
  /** Cbs Code Gte 200 */
  cbs_code_gte_200: string | null;
  /** Cbs Code Gte 1000 */
  cbs_code_gte_1000: string | null;
  /** Cbs Code */
  cbs_code: string;
  /** Is Spirit */
  is_spirit: boolean;
  default_product_bottle_type: ProductBottleType;
}

/** CreateOrUpdateSupplierPriceListConfig */
export interface CreateOrUpdateSupplierPriceListConfig {
  /** Header */
  header: string[];
  /** Column Mapping */
  column_mapping: Record<string, string>;
}

/** CreateOrderForPromotionRequest */
export interface CreateOrderForPromotionRequest {
  /** Orders */
  orders: PromotionalOrder[];
}

/** CreateProductPhotoRequest */
export interface CreateProductPhotoRequest {
  /** Is Internal */
  is_internal: boolean;
  /** Is Primary */
  is_primary: boolean;
  /** Contents Base64 Encoded */
  contents_base64_encoded: string;
}

/** CreateProductPhotoResponse */
export interface CreateProductPhotoResponse {
  /** Artkey */
  artkey: number;
  /** Item Artkey */
  item_artkey?: number | null;
  /** Offer Item Artkey */
  offer_item_artkey?: number | null;
  /** File Name */
  file_name: string;
  /** S3 Location */
  s3_location: string;
  /** S3 Location Thumbnail */
  s3_location_thumbnail: string;
}

/** CustomsStatus */
export enum CustomsStatus {
  T1 = "T1",
  T2 = "T2",
}

/** DashboardStatisticsResponse */
export interface DashboardStatisticsResponse {
  /** Statistics */
  statistics: SupplierStatistic[];
}

/** Delivery */
export interface Delivery {
  /** Artkey */
  artkey: number;
  /** Number Of Block Pallets */
  number_of_block_pallets: number;
  /** Number Of Euro Pallets */
  number_of_euro_pallets: number;
  /** Weight In Kilos */
  weight_in_kilos?: string | null;
}

/** DeliveryStatus */
export enum DeliveryStatus {
  Created = "Created",
  Confirmed = "Confirmed",
}

/** Destination */
export interface Destination {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Street Address */
  street_address: string;
  /** Zip Code */
  zip_code: string;
  /** City */
  city: string;
  /** Country Code */
  country_code: string;
  /** Warehouse Id */
  warehouse_id: string;
  /** Excise Id */
  excise_id: string | null;
  /** Vat Id */
  vat_id: string | null;
}

/** EmailBatchStatus */
export enum EmailBatchStatus {
  Sent = "sent",
  Preparing = "preparing",
  Sending = "sending",
  UnderConstruction = "under_construction",
  WaitingForNextRun = "waiting_for_next_run",
}

/** EmailBatchType */
export enum EmailBatchType {
  ENTIRE_PRICELIST = "ENTIRE_PRICELIST",
  SPECIAL_ONLY = "SPECIAL_ONLY",
  CUSTOM_OFFER = "CUSTOM_OFFER",
  NOTHING = "NOTHING",
  PURCHASE_ENQUIRY = "PURCHASE_ENQUIRY",
}

/** EmailBounceEventType */
export enum EmailBounceEventType {
  Bounce = "bounce",
  DeliveryDelay = "delivery delay",
  Complaint = "complaint",
}

/** ErrorResponse */
export interface ErrorResponse {
  /** Detail */
  detail: string;
}

/** ExcelFormatting */
export interface ExcelFormatting {
  alcohol: AlcoholFormatting;
  volume: VolumeFormatting;
}

/** ExplorePricelistResponse */
export interface ExplorePricelistResponse {
  /** Artkey */
  artkey: number;
  /** Availability Status */
  availability_status: string | null;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  /** Bottle Artkey */
  bottle_artkey: number;
  bottle_refill_status: RefillStatus;
  /** Bottle Volume */
  bottle_volume: string;
  /** Case Gift Box Type */
  case_gift_box_type: string | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number | null;
  /** Currency */
  currency: string;
  /** Customs Status */
  customs_status: string;
  /** Euro Price Per Case */
  euro_price_per_case: string | null;
  /** Incoterm */
  incoterm: string;
  /** Is Available */
  is_available: boolean | null;
  /** Number Of Cases */
  number_of_cases: number | null;
  /** Price Per Case */
  price_per_case: string | null;
  /** Product Artkey */
  product_artkey: number;
  /** Product Category */
  product_category: string;
  /** Product Name */
  product_name: string;
  /** Supplier Client Status */
  supplier_client_status: string | null;
  /** Supplier Company Type */
  supplier_company_type: string | null;
  /** Supplier Name */
  supplier_name: string;
  /** Supplier Price List Artkey */
  supplier_price_list_artkey: number;
  /** Supplier Price List End Date */
  supplier_price_list_end_date: string | null;
  /** Supplier Price List Start Date */
  supplier_price_list_start_date: string | null;
  /** Supplier Price List Supplier Price List Description */
  supplier_price_list_supplier_price_list_description: string;
}

/** ExplorePricelistTotalsResponse */
export interface ExplorePricelistTotalsResponse {
  /** Count */
  count: number;
}

/** ExploreStockResponse */
export interface ExploreStockResponse {
  /** Account Name */
  account_name: string;
  /** Account Slug */
  account_slug: string;
  /** Artkey */
  artkey: number;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  /** Bottle Artkey */
  bottle_artkey: number;
  bottle_refill_status: RefillStatus;
  /** Bottle Volume */
  bottle_volume: string;
  /** Case Customs Status */
  case_customs_status: string;
  /** Case Excise Nl */
  case_excise_nl: string;
  case_gift_box_type: GiftBoxType | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Case Tax Label */
  case_tax_label: string | null;
  /** Item Entry Date */
  item_entry_date: string | null;
  /** Item Euro Total Stock Value */
  item_euro_total_stock_value: string;
  /** Item Euro Was Bought For */
  item_euro_was_bought_for: string;
  /** Item Euro Was Bought For Plus Costs */
  item_euro_was_bought_for_plus_costs: string;
  /** Item Is Target In */
  item_is_target_in: number | null;
  /** Item Lot */
  item_lot: string;
  /** Item Mutation Reference */
  item_mutation_reference: string | null;
  /** Item Number Of Cases Available */
  item_number_of_cases_available: number;
  /** Item Number Of Cases In Purchase */
  item_number_of_cases_in_purchase: number;
  /** Item Number Of Cases In Sales */
  item_number_of_cases_in_sales: number;
  /** Item Number Of Cases In Stock */
  item_number_of_cases_in_stock: number;
  /** Item Reference */
  item_reference: string;
  /** Item Total Stock Value */
  item_total_stock_value: string;
  /** Item Was Bought For */
  item_was_bought_for: string;
  /** Product Name */
  product_name: string;
  /** Purchase Order Artkey */
  purchase_order_artkey: number;
  /** Purchase Order Bought Against Rate */
  purchase_order_bought_against_rate: string;
  /** Expected Delivery Date */
  expected_delivery_date: string | null;
  /** Purchase Order Item Artkey */
  purchase_order_item_artkey: number;
  /** Purchase Order Reference */
  purchase_order_reference: string;
  /** Purchase Order Was Bought In */
  purchase_order_was_bought_in: string;
  /** Sales Order Credit Item Artkey */
  sales_order_credit_item_artkey: number | null;
  /** Supplier Name */
  supplier_name: string;
  /** Warehouse Name */
  warehouse_name: string;
}

/** ExploreStockTotalsResponse */
export interface ExploreStockTotalsResponse {
  /** Count */
  count: number;
  /** In Stock */
  in_stock: number;
  /** In Purchase */
  in_purchase: number;
  /** In Sales */
  in_sales: number;
  /** Available */
  available: number;
}

/** ExportLeadsRequest */
export interface ExportLeadsRequest {
  /** Lead Artkeys */
  lead_artkeys: number[];
}

/** ExportPortalPriceListRequest */
export interface ExportPortalPriceListRequest {
  /** Offer Item Artkeys */
  offer_item_artkeys?: number[] | null;
  /** Search Terms */
  search_terms: string[];
  /** Offer Hash */
  offer_hash?: string | null;
  offer_item_type?: OfferItemFilterType | null;
  /**
   * Category Artkeys
   * @default []
   */
  category_artkeys?: number[];
  /**
   * Availabilities
   * @default []
   */
  availabilities?: AvailabilityType[];
  /** Volume */
  volume?: number | null;
  /** Alcohol Percentage */
  alcohol_percentage?: number | null;
  /**
   * Packaging
   * @default []
   */
  packaging?: string[];
  /** Refill Status */
  refill_status?: string | null;
  /** Include Case Artkeys */
  include_case_artkeys: number[];
  /** Exclude Case Artkeys */
  exclude_case_artkeys: number[];
  formatting: ExcelFormatting;
}

/** FileHolder */
export interface FileHolder {
  /** File Contents */
  file_contents: string | null;
  /** File Name */
  file_name: string | null;
}

/** FilterDefinition */
export interface FilterDefinition {
  /** Name */
  name: string;
  filter_type: FilterType;
  /** Help */
  help?: string | null;
}

/** FilterType */
export enum FilterType {
  TOGGLE = "TOGGLE",
  SELECT_SINGLE = "SELECT_SINGLE",
  SELECT_MULTIPLE = "SELECT_MULTIPLE",
  SELECT_RANGE = "SELECT_RANGE",
  TEXT = "TEXT",
}

/** ForgotPasswordRequest */
export interface ForgotPasswordRequest {
  /** Email Address */
  email_address: string;
}

/** GenerateRedeemCodeRequest */
export interface GenerateRedeemCodeRequest {
  /** Lead Artkeys */
  lead_artkeys: number[];
}

/** GenerateRedeemCodeResponse */
export interface GenerateRedeemCodeResponse {
  /** Lead Artkey */
  lead_artkey: number;
  /** Redeem Code */
  redeem_code?: string | null;
  /**
   * Redeem Code Valid Until
   * @format date-time
   */
  redeem_code_valid_until: string;
}

/** GetAccountResponse */
export interface GetAccountResponse {
  /** Slug */
  slug: string;
  /** Domain */
  domain: string;
  /** Informal Name */
  informal_name: string;
  /** Name */
  name: string;
  /** Address */
  address: string;
  /** Zip Code */
  zip_code: string;
  /** City */
  city: string;
  /** Country Code */
  country_code: string;
  /** Telephone Number */
  telephone_number: string;
  /** Emailaddress */
  emailaddress: string;
  /** Iban Eur */
  iban_eur: string | null;
  /** Iban Usd */
  iban_usd: string | null;
  /** Iban Gbp */
  iban_gbp: string | null;
  /** Chamber Of Commerce Number */
  chamber_of_commerce_number: string;
  /** Vat Id */
  vat_id: string;
}

/** GetAllCurrenciesResponse */
export interface GetAllCurrenciesResponse {
  /** Artkey */
  artkey: number;
  /** Currency */
  currency: string;
  /** Exchange Rate */
  exchange_rate: string;
  /**
   * Date
   * @format date-time
   */
  date: string;
  /** Portal Exchange Rate */
  portal_exchange_rate: string;
}

/** GetAllUsersResponse */
export interface GetAllUsersResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Email Address */
  email_address: string;
}

/** GetBulkOfferForOfferItemsResponse */
export interface GetBulkOfferForOfferItemsResponse {
  /** Offer Item Artkey */
  offer_item_artkey: number;
  /** Offer Title */
  offer_title: string;
  /** Offer Artkey */
  offer_artkey: number;
  /** Bulk Price */
  bulk_price: string | null;
}

/** GetBuyFromAccountPurchaseOrderResponse */
export interface GetBuyFromAccountPurchaseOrderResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  status: PurchaseOrderStatus;
  /** Has Supplier Invoice */
  has_supplier_invoice: boolean;
  /** Account Artkey */
  account_artkey: number;
  /** Account Slug */
  account_slug: string;
  /** Account Name */
  account_name: string;
}

/** GetBuyFromAccountRelatedSalesOrderResponse */
export interface GetBuyFromAccountRelatedSalesOrderResponse {
  /** Artkey */
  artkey: number;
}

/** GetBuyFromAccountSalesOrderResponse */
export interface GetBuyFromAccountSalesOrderResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  sales_order_status: SalesOrderStatus;
  /** Account Artkey */
  account_artkey: number;
  /** Account Slug */
  account_slug: string;
  /** Account Name */
  account_name: string;
}

/** GetCandidatesCollectionResponse */
export interface GetCandidatesCollectionResponse {
  /** Artkey */
  artkey: number;
  /** Candidate */
  candidate: (string | null)[];
}

/** GetContactPersonForRelationResponse */
export interface GetContactPersonForRelationResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Emailaddress */
  emailaddress: string;
}

/** GetEmailBatchCollectionViewResponse */
export interface GetEmailBatchCollectionViewResponse {
  /** Artkey */
  artkey: number;
  status: EmailBatchStatus;
  email_batch_type: EmailBatchType;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Subject */
  subject: string;
  /** Created By Name */
  created_by_name: string;
  /** Created By Artkey */
  created_by_artkey: number;
  /**
   * Next Run
   * @format date-time
   */
  next_run: string;
  /** Dispatched On */
  dispatched_on?: string | null;
  /** Is Pending */
  is_pending: boolean;
  /** Is Completed */
  is_completed: boolean;
  /** Number Of Mails */
  number_of_mails: number;
  /** Number Of Unsent Mails */
  number_of_unsent_mails: number;
}

/** GetEmailBounceCollectionViewResponse */
export interface GetEmailBounceCollectionViewResponse {
  /** Artkey */
  artkey: number;
  /**
   * Last Event On
   * @format date-time
   */
  last_event_on: string;
  /** Number Of Events */
  number_of_events: number;
  /** Email Address */
  email_address: string;
  /** Contact Person Artkey */
  contact_person_artkey: number | null;
  /** Contact Person Name */
  contact_person_name: string | null;
  /** Relation Artkey */
  relation_artkey: number | null;
  /** Relation Name */
  relation_name: string | null;
  event_type: EmailBounceEventType;
  /** Event Summary */
  event_summary: string;
}

/** GetExactSalesOrderCollectionViewResponse */
export interface GetExactSalesOrderCollectionViewResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  /** Relation Name */
  relation_name: string;
  /** Relation Artkey */
  relation_artkey: number;
  sales_order_status: SalesOrderStatus;
  /**
   * Is Invoiced On
   * @format date-time
   */
  is_invoiced_on: string;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Was Sold For */
  was_sold_for: string;
  /** Was Sold In */
  was_sold_in: string;
}

/** GetFilterStatisticsRequest */
export interface GetFilterStatisticsRequest {
  /** Offer Item Artkeys */
  offer_item_artkeys?: number[] | null;
  /** Search Terms */
  search_terms: string[];
  /** Offer Hash */
  offer_hash?: string | null;
  offer_item_type?: OfferItemFilterType | null;
  /**
   * Category Artkeys
   * @default []
   */
  category_artkeys?: number[];
  /**
   * Availabilities
   * @default []
   */
  availabilities?: AvailabilityType[];
  /** Volume */
  volume?: number | null;
  /** Alcohol Percentage */
  alcohol_percentage?: number | null;
  /**
   * Packaging
   * @default []
   */
  packaging?: string[];
  /** Refill Status */
  refill_status?: string | null;
}

/** GetHiddenInformationResponse */
export interface GetHiddenInformationResponse {
  /** Hidden Country Codes */
  hidden_country_codes: string[];
  /** Hidden Relations */
  hidden_relations: HiddenSupplier[];
}

/** GetHiddenItemsResponse */
export interface GetHiddenItemsResponse {
  /** Artkey */
  artkey: number;
  hidden_by_rule: RelationPriceListHiddenRule;
  /** Product Name */
  product_name: string;
  /** Product Category Name */
  product_category_name: string;
  /** Brand Name */
  brand_name?: string | null;
  /** Brand Holder Name */
  brand_holder_name?: string | null;
  /** Case Article Code */
  case_article_code: string;
  /** Case Artkey */
  case_artkey: number;
  /** Case Customs Status */
  case_customs_status: string;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Bottle Gtins */
  bottle_gtins?: string | null;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
}

/** GetIncotermsResponse */
export interface GetIncotermsResponse {
  /** Code */
  code: string;
  /** Description */
  description: string;
  /** Transport Costs */
  transport_costs: boolean;
}

/** GetInsuranceTypeResponse */
export interface GetInsuranceTypeResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Fee */
  fee: string;
}

/** GetItemCollectionViewResponse */
export interface GetItemCollectionViewResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  /** Entry Date */
  entry_date: string | null;
  /** Lot */
  lot: string;
  /** Remark */
  remark: string | null;
  /** Bottle Gtin Code */
  bottle_gtin_code: string | null;
  /** Case Gtin Code */
  case_gtin_code: string | null;
  /** Country Of Origin */
  country_of_origin: string | null;
  /** Number Of Cases In Sales */
  number_of_cases_in_sales: number;
  /** Number Of Cases In Purchase */
  number_of_cases_in_purchase: number;
  /** Number Of Shipped Cases */
  number_of_shipped_cases: number;
  /** Number Of Cases Available */
  number_of_cases_available: number;
  /** Number Of Cases In Stock */
  number_of_cases_in_stock: number;
  /** Stock Age */
  stock_age: number | null;
  /** Euro Total Stock Value */
  euro_total_stock_value: string;
  /** Euro Was Bought For */
  euro_was_bought_for: string;
  /** Number Of Product Photos */
  number_of_product_photos: number;
  /** Cases Per Pallet Layer */
  cases_per_pallet_layer: number | null;
  /** Cases Per Pallet */
  cases_per_pallet: number | null;
  /** Case Length */
  case_length: string | null;
  /** Case Height */
  case_height: string | null;
  /** Case Width */
  case_width: string | null;
  /** Case Weight */
  case_weight: string | null;
  /** Warehouse Name */
  warehouse_name: string | null;
  /** Warehouse Artkey */
  warehouse_artkey: number | null;
  /** Account Slug */
  account_slug: string;
  /** Account Artkey */
  account_artkey: number;
  /** Account Name */
  account_name: string;
  /** Price Per Case */
  price_per_case: string | null;
  /** Excise Nl Per Case */
  excise_nl_per_case: string | null;
  /** Item Mutation Reference */
  item_mutation_reference: string | null;
  /** Product Name */
  product_name: string;
  /** Product Category Name */
  product_category_name: string;
  /** Case Artkey */
  case_artkey: number;
  /** Best Before Date */
  best_before_date: string | null;
  /** Article Code */
  article_code: string;
  /** Serialized Item Tags */
  serialized_item_tags: string;
  /** Number Of Bottles */
  number_of_bottles: number;
  gift_box_type: GiftBoxType | null;
  /** Tax Label */
  tax_label: string | null;
  customs_status: CustomsStatus;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  /** Volume */
  volume: string;
  refill_status: RefillStatus;
  /** Purchase Order Reference */
  purchase_order_reference: string;
  /** Purchase Order Artkey */
  purchase_order_artkey: number;
  /** Supplier Artkey */
  supplier_artkey: number;
  /** Expected Delivery Date */
  expected_delivery_date: string | null;
  /** Purchase Order Item Artkey */
  purchase_order_item_artkey: number;
  /** Total Costs Per Case */
  total_costs_per_case: string;
}

/** GetItemResponse */
export interface GetItemResponse {
  /** Artkey */
  artkey: number;
  /** Entry Date */
  entry_date: string | null;
  /** Lot */
  lot: string | null;
  /** Reference */
  reference: string;
  /** Number Of Cases In Sales */
  number_of_cases_in_sales: number;
  /** Number Of Cases */
  number_of_cases: number;
  /** Number Of Cases In Purchase */
  number_of_cases_in_purchase: number;
  /** Number Of Cases In Stock */
  number_of_cases_in_stock: number;
  /** Country Of Origin */
  country_of_origin: string | null;
  /** Remark */
  remark?: string | null;
  /** Bottle Gtin Code */
  bottle_gtin_code?: string | null;
  /** Case Gtin Code */
  case_gtin_code?: string | null;
  /** Bottle Coding */
  bottle_coding?: string | null;
  /** Bottle Lot */
  bottle_lot?: string | null;
  /** Cases Per Pallet */
  cases_per_pallet?: number | null;
  /** Cases Per Pallet Layer */
  cases_per_pallet_layer?: number | null;
  warehouse?: ItemWarehouseResponse | null;
  case: ItemCaseResponse;
  account: ItemAccountResponse;
  purchase_order_item: ItemPurchaseOrderItemResponse;
}

/** GetLocationResponse */
export interface GetLocationResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  destination_type: LocationType;
  /** Can Handle Our Stock */
  can_handle_our_stock: boolean;
  /** Street Address */
  street_address: string;
  /** Zip Code */
  zip_code: string;
  /** City */
  city: string;
  /** Country Code */
  country_code: string;
  /** Region Code */
  region_code?: string | null;
  /** Loendersloot Address Id */
  loendersloot_address_id?: number | null;
  /** Emailaddress */
  emailaddress?: string | null;
  /** Excise Id */
  excise_id?: string | null;
  /** Vat Id */
  vat_id?: string | null;
  /** Warehouse Id */
  warehouse_id?: string | null;
  /** Warehouse Base Costs */
  warehouse_base_costs?: string | null;
  /** Warehouse Costs Per Case */
  warehouse_costs_per_case?: string | null;
  /** Currency */
  currency?: string | null;
}

/** GetLoenderslootMessage */
export interface GetLoenderslootMessage {
  /** Artkey */
  artkey: number;
  status: LoenderslootMessageStatus;
  /** Message Type */
  message_type: string;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Raw Xml */
  raw_xml: string;
}

/** GetLoenderslootStockReconResponse */
export interface GetLoenderslootStockReconResponse {
  /** Artkey */
  artkey: number;
  /** Account Slug */
  account_slug: string;
  /** Account Name */
  account_name: string;
  /** File Name */
  file_name: string;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Total Items */
  total_items: number;
  /** Total Items Found In Discover */
  total_items_found_in_discover: number;
  /** Total Items Not Found In Discover */
  total_items_not_found_in_discover: number;
  /** Total Items Found In Loendersloot */
  total_items_found_in_loendersloot: number;
  /** Total Items Not Found In Loendersloot */
  total_items_not_found_in_loendersloot: number;
}

/** GetLoenderslootStockReconResultResponse */
export interface GetLoenderslootStockReconResultResponse {
  /** Artkey */
  artkey: number;
  /** Lot */
  lot?: string | null;
  /** Item Artkey */
  item_artkey?: number | null;
  /** Item Reference */
  item_reference?: string | null;
  /** Number Of Cases */
  number_of_cases?: number | null;
  /** Number Of Shipped Cases */
  number_of_shipped_cases?: number | null;
  /** Number Of Cases In Sales */
  number_of_cases_in_sales?: number | null;
  /** Purchase Order Reference */
  purchase_order_reference?: string | null;
  /** Case Artkey */
  case_artkey?: number | null;
  /** Bottle Artkey */
  bottle_artkey?: number | null;
  /** Bottle Specs */
  bottle_specs?: string | null;
  /** Product Name */
  product_name?: string | null;
  result: StockReconciliationResult;
  /** Result Text */
  result_text: string;
  /** Line */
  line: string;
}

/** GetLoenderslootTagResponse */
export interface GetLoenderslootTagResponse {
  /** Artkey */
  artkey: number;
  /** Code */
  code: string;
  /** Description */
  description: string;
  /** Unapprove When Added */
  unapprove_when_added: boolean;
  /** Show As Feature */
  show_as_feature: boolean;
  /** Forbid In Stock */
  forbid_in_stock: boolean;
  /** Is Gift Box */
  is_gift_box: boolean;
  /** Item Tag Artkey */
  item_tag_artkey: number;
  /** Is Portal Filter */
  is_portal_filter: boolean;
}

/** GetOTPSecretRequest */
export interface GetOTPSecretRequest {
  /** Pre Auth Token */
  pre_auth_token: string;
}

/** GetOTPSecretResponse */
export interface GetOTPSecretResponse {
  /** Otp Secret */
  otp_secret: string;
  /** Otp Secret Url */
  otp_secret_url: string;
}

/** GetOfferItemCompareWithMarketResponse */
export interface GetOfferItemCompareWithMarketResponse {
  /** Artkey */
  artkey: number;
  /** Price Per Case */
  price_per_case: string;
  /** Old Price Per Case */
  old_price_per_case: string;
  /** Is Hidden */
  is_hidden: boolean;
  /** Delivery Period In Weeks */
  delivery_period_in_weeks: number | null;
  /** Maximum Order Quantity */
  maximum_order_quantity: number | null;
  /** Minimum Order Quantity */
  minimum_order_quantity: number | null;
  /** Price Age In Days */
  price_age_in_days: number | null;
  lowest_market_price_competitor?: CompetitorPrice | null;
  /** Stock Items */
  stock_items: StockItem[];
  /** Product Name */
  product_name: string;
  /** Number Of Bottles */
  number_of_bottles: number;
  gift_box_type: GiftBoxType | null;
  /** Customs Status */
  customs_status: string;
  /** Tax Label */
  tax_label: string | null;
  /** Article Code */
  article_code: string;
  /** Case Serialized Item Tags */
  case_serialized_item_tags?: string | null;
  /** Best Before Date */
  best_before_date: string | null;
  /** Item Remark */
  item_remark: string | null;
  /** Item Number Of Cases */
  item_number_of_cases: number;
  /** Item Number Of Cases In Stock */
  item_number_of_cases_in_stock: number;
  /** Item Number Of Cases In Sales */
  item_number_of_cases_in_sales: number;
  /** Item Number Of Cases In Purchase */
  item_number_of_cases_in_purchase: number;
  /** Item Number Of Cases Available */
  item_number_of_cases_available: number;
  /** Item Stock Age */
  item_stock_age: number;
  /** Avg Purchase Price */
  avg_purchase_price: string;
  /** Avg Margin Percentage */
  avg_margin_percentage: string;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Volume */
  volume: string;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  refill_status: RefillStatus;
}

/** GetOfferItemsCollectionViewResponse */
export interface GetOfferItemsCollectionViewResponse {
  /** Artkey */
  artkey: number;
  /** Is Hidden */
  is_hidden: boolean;
  offer_item_type: OfferItemType;
  /** Minimum Order Quantity */
  minimum_order_quantity: number | null;
  /** Maximum Order Quantity */
  maximum_order_quantity: number | null;
  /** Delivery Period In Weeks */
  delivery_period_in_weeks: number | null;
  /** Originating Spli Artkey */
  originating_spli_artkey: number | null;
  /** Price Per Case */
  price_per_case: string;
  /** Spot Price Per Case */
  spot_price_per_case: string | null;
  /** Previous Price Per Case */
  previous_price_per_case: string | null;
  /** Price Up */
  price_up: boolean;
  /** Price Down */
  price_down: boolean;
  /** Price Age In Days */
  price_age_in_days: number;
  /** Market Rank */
  market_rank: number | null;
  /** Market Total */
  market_total: number | null;
  /** Avg Competitor Diff */
  avg_competitor_diff: string | null;
  /** Avg Competitor Diff Percentage */
  avg_competitor_diff_percentage: string | null;
  /** Number Of Bottles */
  number_of_bottles: number;
  /** Article Code */
  article_code: string;
  gift_box_type: GiftBoxType | null;
  /** Tax Label */
  tax_label: string | null;
  /** Best Before Date */
  best_before_date: string | null;
  customs_status: CustomsStatus;
  /** Case Quantity Sold Last Month */
  case_quantity_sold_last_month: number;
  /** Case Quantity Sold Last Quarter */
  case_quantity_sold_last_quarter: number;
  /** Case Quantity Sold Last Year */
  case_quantity_sold_last_year: number;
  /** Case Quantity Bought Last Month */
  case_quantity_bought_last_month: number;
  /** Case Quantity Bought Last Quarter */
  case_quantity_bought_last_quarter: number;
  /** Case Quantity Bought Last Year */
  case_quantity_bought_last_year: number;
  /** Case Excise Nl */
  case_excise_nl: string;
  /** Case Serialized Item Tags */
  case_serialized_item_tags?: string | null;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  /** Volume */
  volume: string;
  refill_status: RefillStatus;
  /** Product Category Name */
  product_category_name: string;
  /** Product Category Artkey */
  product_category_artkey: number;
  /** Product Name */
  product_name: string;
  /** Product Artkey */
  product_artkey: number;
  /** Number Of Cases In Stock */
  number_of_cases_in_stock: number;
  /** Number Of Cases Available */
  number_of_cases_available: number;
  /** Number Of Cases In Sales */
  number_of_cases_in_sales: number;
  /** Number Of Cases In Purchase */
  number_of_cases_in_purchase: number;
  /** Max Stock Age */
  max_stock_age: number;
  /** Min Stock Age */
  min_stock_age: number;
  /** Avg Euro Was Bought For */
  avg_euro_was_bought_for: string | null;
  /** Avg Margin Percentage */
  avg_margin_percentage: string | null;
}

/** GetOrderCollectionViewResponse */
export interface GetOrderCollectionViewResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  /** Account Slug */
  account_slug: string;
  /** Portal Status */
  portal_status: string;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Created By */
  created_by: string | null;
  /** Number Of Bottles */
  number_of_bottles: number;
  /** Number Of Cases */
  number_of_cases: number;
  /** Total Value */
  total_value: string;
  /** Currency */
  currency: string;
  /** Is Invoiced On */
  is_invoiced_on: string | null;
  /** Invoice Number */
  invoice_number: number | null;
}

/** GetPortalOfferItemResponse */
export interface GetPortalOfferItemResponse {
  /** Artkey */
  artkey: number;
  /** Related Offer Item Artkey */
  related_offer_item_artkey?: number | null;
  /** Product Name */
  product_name: string;
  /** Product Category */
  product_category: string;
  offer_item_type: AvailabilityType;
  /**
   * An offer item for a relation can be these values.
   *
   * Note: Can be different than the OfferItemType (or RelationPriceListOfferItemType).
   *     This value is determined on more live data than the RelationPriceList.
   */
  offer_type: OfferType;
  /** Favourite Artkey */
  favourite_artkey?: number | null;
  /** Validity Date */
  validity_date?: string | null;
  /** Vat Percentage */
  vat_percentage?: string | null;
  /** Delivery Period */
  delivery_period?: number | null;
  /** Minimum Quantity */
  minimum_quantity?: number | null;
  /**
   * Entry Date
   * @format date-time
   */
  entry_date: string;
  /** List Quantity */
  list_quantity: number;
  /** Excise Per Case */
  excise_per_case?: string | null;
  /** List Price */
  list_price: string;
  /** Original Price Per Case */
  original_price_per_case: string;
  /** Bottle Gtin Code */
  bottle_gtin_code: string[];
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
  /** Case Artkey */
  case_artkey: number;
  /** Case Article Code */
  case_article_code: string;
  case_gift_box_type?: GiftBoxType | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Case Tax Label */
  case_tax_label?: string | null;
  /** Item Best Before Date */
  item_best_before_date?: string | null;
  /** Case Customs Status */
  case_customs_status: string;
  /** Item Damages */
  item_damages?: string | null;
  /** Item General Tags */
  item_general_tags?: string | null;
  /** Item Pack Size */
  item_pack_size?: string | null;
  /** Item Packaging */
  item_packaging?: string | null;
  /** Cases Per Pallet */
  cases_per_pallet?: number | null;
  /** Cases Per Pallet Layer */
  cases_per_pallet_layer?: number | null;
  /** Case Weight */
  case_weight?: string | null;
  /** Currency */
  currency: string;
}

/** GetPortalOfferItemsRequest */
export interface GetPortalOfferItemsRequest {
  /** Offer Item Artkeys */
  offer_item_artkeys?: number[] | null;
  /** Search Terms */
  search_terms: string[];
  /** Offer Hash */
  offer_hash?: string | null;
  offer_item_type?: OfferItemFilterType | null;
  /**
   * Category Artkeys
   * @default []
   */
  category_artkeys?: number[];
  /**
   * Availabilities
   * @default []
   */
  availabilities?: AvailabilityType[];
  /** Volume */
  volume?: number | null;
  /** Alcohol Percentage */
  alcohol_percentage?: number | null;
  /**
   * Packaging
   * @default []
   */
  packaging?: string[];
  /** Refill Status */
  refill_status?: string | null;
  /** Limit */
  limit: number;
  /** Offset */
  offset: number;
  /** Sort By */
  sort_by: string;
  /** Sort Ascending */
  sort_ascending: boolean;
}

/** GetPortalOfferItemsResponse */
export interface GetPortalOfferItemsResponse {
  /** Total */
  total: number;
  /** Portal Offer Items */
  portal_offer_items: GetPortalOfferItemResponse[];
}

/** GetPortalProfileResponse */
export interface GetPortalProfileResponse {
  /** Artkey */
  artkey: number;
  /** Username */
  username: string;
  /** Email Address */
  email_address: string;
  /** Language */
  language: string;
  /** First Name */
  first_name: string | null;
  /** Last Name */
  last_name: string | null;
  /** Name */
  name: string;
  /** Phone Number */
  phone_number: string | null;
  /** Mobile Phone Number */
  mobile_phone_number: string | null;
  /** Tawk Hash */
  tawk_hash: string;
  /** Country Code */
  country_code: string;
  /** Currency */
  currency: string;
  /** Minimum Order Amount */
  minimum_order_amount: string | null;
  price_preference: PricePreference;
  /** Include Excise In Price */
  include_excise_in_price: boolean;
  /** Show Excise */
  show_excise: boolean;
  /** Relation Name */
  relation_name: string;
  /** Customs Visibility */
  customs_visibility: string | null;
  sales_manager: GetPortalProfileSalesManager;
}

/** GetPortalProfileSalesManager */
export interface GetPortalProfileSalesManager {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Email Address */
  email_address: string;
  /** Mobile Telephone Number */
  mobile_telephone_number: string | null;
  /** Phone Number */
  phone_number: string;
}

/** GetPriceListCollectionViewResponse */
export interface GetPriceListCollectionViewResponse {
  /** Artkey */
  artkey: number;
  /** Product Name */
  product_name: string;
  /** Product Category Name */
  product_category_name: string;
  /** Case Artkey */
  case_artkey: number;
  /** Case Customs Status */
  case_customs_status: string;
  /** Case Article Code */
  case_article_code: string;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Case Serialized Item Tags */
  case_serialized_item_tags?: string | null;
  /** Case Gift Box Type */
  case_gift_box_type?: string | null;
  /** Case Best Before Date */
  case_best_before_date?: string | null;
  /** Case Tax Label */
  case_tax_label?: string | null;
  /** Cases Per Pallet */
  cases_per_pallet?: number | null;
  /** Remark */
  remark?: string | null;
  /** Number Of Cases In Stock */
  number_of_cases_in_stock: number;
  /** Number Of Cases In Purchase */
  number_of_cases_in_purchase: number;
  /** Number Of Cases In Sales */
  number_of_cases_in_sales: number;
  /** Number Of Cases Available */
  number_of_cases_available: number;
  /** Lots */
  lots?: string | null;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Bottle Gtins */
  bottle_gtins?: string | null;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
  /** Relation Artkey */
  relation_artkey: number;
  /** Tbo Supplier Artkey */
  tbo_supplier_artkey?: number | null;
  /** Tbo Supplier Name */
  tbo_supplier_name?: string | null;
  /** Originating Spl Artkey */
  originating_spl_artkey?: number | null;
  /** Originating Spl Name */
  originating_spl_name?: string | null;
  /** Euro Total Stock Value */
  euro_total_stock_value: string;
  /** Stock Age */
  stock_age: number;
  /** Has Product Photos */
  has_product_photos: boolean;
  /** Minimum Quantity */
  minimum_quantity?: number | null;
  /** List Quantity */
  list_quantity: number;
  /** Delivery Period */
  delivery_period?: number | null;
  /** List Price */
  list_price: string;
  /** Excise Per Case */
  excise_per_case?: string | null;
  /** Includes Excise In Price */
  includes_excise_in_price: boolean;
  /** Currency */
  currency: string;
  /** Original Price Per Case */
  original_price_per_case: string;
  /**
   * An offer item for a relation can be these values.
   *
   * Note: Can be different than the OfferItemType (or RelationPriceListOfferItemType).
   *     This value is determined on more live data than the RelationPriceList.
   */
  offer_type: OfferType;
  /** Avg Was Bought For */
  avg_was_bought_for?: string | null;
}

/** GetProductCategoriesResponse */
export interface GetProductCategoriesResponse {
  /** Categories */
  categories: Fact2ServerApiDiscoverProductCategoryProductCategoryGetProductCategoriesResponseCategory[];
}

/** GetProductCategoryCollectionResponse */
export interface GetProductCategoryCollectionResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Be Vat Rate Percentage */
  be_vat_rate_percentage: string;
  /** Nl Vat Rate Percentage */
  nl_vat_rate_percentage: string;
  /** De Vat Rate Percentage */
  de_vat_rate_percentage: string;
  /** Cbs Code Gte 200 */
  cbs_code_gte_200: string | null;
  /** Cbs Code Gte 1000 */
  cbs_code_gte_1000: string | null;
  /** Cbs Code */
  cbs_code: string;
  /** Is Spirit */
  is_spirit: boolean;
  default_product_bottle_type: ProductBottleType;
  /** Parent Product Category Name */
  parent_product_category_name: string | null;
}

/** GetProductCategoryResponse */
export interface GetProductCategoryResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /**
   * Alternative Names
   * @default []
   */
  alternative_names?: string[];
  /** Be Vat Rate Artkey */
  be_vat_rate_artkey: number;
  /** Nl Vat Rate Artkey */
  nl_vat_rate_artkey: number;
  /** De Vat Rate Artkey */
  de_vat_rate_artkey: number;
  /** Cbs Code */
  cbs_code: string;
  /** Cbs Code Gte 200 */
  cbs_code_gte_200: string | null;
  /** Cbs Code Gte 1000 */
  cbs_code_gte_1000: string | null;
  /** Is Spirit */
  is_spirit: boolean;
  default_product_bottle_type: ProductBottleType;
  /** Parent Artkey */
  parent_artkey: number | null;
}

/** GetProductCategorySelectResponse */
export interface GetProductCategorySelectResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
}

/** GetProductPhotoResponse */
export interface GetProductPhotoResponse {
  /** Artkey */
  artkey: number;
  /** File Name */
  file_name: string;
  /** Thumbnail File Name */
  thumbnail_file_name?: string | null;
  /** S3 Location */
  s3_location: string;
  /** S3 Location Thumbnail */
  s3_location_thumbnail?: string | null;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /**
   * Was Last Updated On
   * @format date-time
   */
  was_last_updated_on: string;
  /** Was Last Updated By Name */
  was_last_updated_by_name?: string | null;
  /** Is Internal */
  is_internal: boolean;
  /** Rank */
  rank: number;
  /**
   * Upload Timestamp
   * @format date-time
   */
  upload_timestamp: string;
  /** Verified By Name */
  verified_by_name?: string | null;
}

/** GetProductsResponse */
export interface GetProductsResponse {
  /** Artkey */
  artkey: number;
  /** Bottles */
  bottles: ProductBottle[];
  /** Name */
  name: string;
  /** Default Country Code */
  default_country_code: string | null;
  product_category: Fact2ServerApiDiscoverProductProductGetProductsResponseProductCategory;
}

/** GetPurchaseOrderCostResponse */
export interface GetPurchaseOrderCostResponse {
  /** Additional Costs */
  additional_costs: string;
  calculation_details: CalculationDetails;
  /** Total Costs */
  total_costs: string;
  /** Total Costs Per Case */
  total_costs_per_case: string;
  /** Transport Costs */
  transport_costs: string;
  /** Diesel Surcharge Amount */
  diesel_surcharge_amount: string;
  /** Pallet Costs */
  pallet_costs: string;
  /** Other Costs */
  other_costs: string;
  /** Waste Fund Costs */
  waste_fund_costs: string | null;
  /** Warehouse Base Costs */
  warehouse_base_costs: string | null;
  /** Handover Costs */
  handover_costs: string | null;
  /** Spirits Costs */
  spirits_costs: string | null;
  /** Non Spirits Costs */
  non_spirits_costs: string | null;
  /** Inspection Costs */
  inspection_costs: string | null;
  /** Duty Drawback */
  duty_drawback: string | null;
}

/** GetPurchaseOrderForSPLIResult */
export interface GetPurchaseOrderForSPLIResult {
  /** Spli Artkey */
  spli_artkey: number;
  /** Purchase Order Artkey */
  purchase_order_artkey: number;
  /** Purchase Order Reference */
  purchase_order_reference: string;
  /** Number Of Cases */
  number_of_cases: number;
}

/** GetPurchaseOrderItemBottle */
export interface GetPurchaseOrderItemBottle {
  /** Artkey */
  artkey: number;
  product: GetPurchaseOrderItemProduct;
  /** Volume */
  volume: string;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  refill_status: RefillStatus;
}

/** GetPurchaseOrderItemCase */
export interface GetPurchaseOrderItemCase {
  /** Artkey */
  artkey: number;
  bottle: GetPurchaseOrderItemBottle;
  /** Number Of Bottles */
  number_of_bottles: number;
  gift_box_type: GiftBoxType | null;
  /** Best Before Date */
  best_before_date: string | null;
  /** Tax Label */
  tax_label: string | null;
  customs_status: CustomsStatus;
}

/** GetPurchaseOrderItemProduct */
export interface GetPurchaseOrderItemProduct {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
}

/** GetPurchaseOrderItemRefersToSpli */
export interface GetPurchaseOrderItemRefersToSpli {
  /** Artkey */
  artkey: number;
  /** Price Per Case */
  price_per_case: string;
}

/** GetPurchaseOrderItemResponse */
export interface GetPurchaseOrderItemResponse {
  /** Artkey */
  artkey: number;
  /** Number Of Cases */
  number_of_cases: number;
  /** Was Bought For */
  was_bought_for: string;
  /** Show Bid Price On Rfp */
  show_bid_price_on_rfp: boolean;
  /** Show As New On Rfp */
  show_as_new_on_rfp: boolean;
  /** Is In Stock Loendersloot */
  is_in_stock_loendersloot: boolean;
  case: GetPurchaseOrderItemCase;
  refers_to: GetPurchaseOrderItemRefersToSpli | null;
}

/** GetPurchaseOrderItemsCollectionViewResponse */
export interface GetPurchaseOrderItemsCollectionViewResponse {
  /** Artkey */
  artkey: number;
  /** Remark */
  remark?: string | null;
  /** Number Of Cases */
  number_of_cases: number;
  /** Country Of Origin */
  country_of_origin?: string | null;
  /** Bottle Gtin Code */
  bottle_gtin_code?: string | null;
  /** Case Gtin Code */
  case_gtin_code?: string | null;
  /** Number Of Cases In Tbo */
  number_of_cases_in_tbo: number;
  /** Number Of Cases In Soi */
  number_of_cases_in_soi: number;
  /** Lots */
  lots: string[];
  /** Hidden In Country Codes */
  hidden_in_country_codes: string[];
  /** Hidden For Supplier Artkeys */
  hidden_for_supplier_artkeys: number[];
  /** Same Bottle As Origin */
  same_bottle_as_origin?: boolean | null;
  /** Purchase Order Artkey */
  purchase_order_artkey: number;
  purchase_order_status: PurchaseOrderStatus;
  /** Was Bought In */
  was_bought_in: string;
  /** Euro Was Bought For Plus Costs */
  euro_was_bought_for_plus_costs: string;
  /** Bought Against Rate */
  bought_against_rate: string;
  /** Supplier Artkey */
  supplier_artkey: number;
  /** Spli Price Per Case */
  spli_price_per_case?: string | null;
  /** Spli Artkey */
  spli_artkey?: number | null;
  /** Spli Hs Code */
  spli_hs_code?: string | null;
  /** Spli Suppliers Item Code */
  spli_suppliers_item_code?: string | null;
  /** Spli Case Gross Weight In Kg */
  spli_case_gross_weight_in_kg?: string | null;
  /** Spli Aux Info */
  spli_aux_info?: string | null;
  /** Spl Artkey */
  spl_artkey?: number | null;
  /** Was Bought For */
  was_bought_for: string;
  /** Was Bought For Plus Costs */
  was_bought_for_plus_costs: string;
  /** Total Price */
  total_price: string;
  /** Suggested Price Per Case */
  suggested_price_per_case?: string | null;
  /** Product Name */
  product_name: string;
  /** Requires Import Statement */
  requires_import_statement: boolean;
  /** Category Name */
  category_name: string;
  /** Case Artkey */
  case_artkey: number;
  /** Number Of Bottles */
  number_of_bottles: number;
  /** Customs Status */
  customs_status: string;
  /** Weight */
  weight?: string | null;
  /** Dimensions */
  dimensions?: string | null;
  /** Best Before Date */
  best_before_date?: string | null;
  /** Serialized Item Tags */
  serialized_item_tags?: string | null;
  /** Tax Label */
  tax_label?: string | null;
  /** Cases Per Pallet */
  cases_per_pallet?: number | null;
  /** Cases Per Pallet Layer */
  cases_per_pallet_layer?: number | null;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Volume */
  volume: string;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  gift_box_type?: GiftBoxType | null;
  refill_status: RefillStatus;
}

/** GetPurchaseOrderResponse */
export interface GetPurchaseOrderResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  status: PurchaseOrderStatus;
  /** Remark */
  remark: string | null;
  /** Supplier Reference */
  supplier_reference: string | null;
  /** Incoterm */
  incoterm: string;
  /** Incoterm Location */
  incoterm_location: string;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Intake Date */
  intake_date: string | null;
  /** Confirmation Date */
  confirmation_date: string | null;
  /** Is Communicated On */
  is_communicated_on: string | null;
  /** Was Sent To Financial Information System On */
  was_sent_to_financial_information_system_on: string | null;
  /** Was Bought In */
  was_bought_in: string;
  /** Bought Against Rate */
  bought_against_rate: string;
  /** Was Bought For */
  was_bought_for: string;
  /** Number Of Euro Pallets */
  number_of_euro_pallets: number | null;
  /** Number Of Block Pallets */
  number_of_block_pallets: number | null;
  /** Warehouse Instruction */
  warehouse_instruction: string | null;
  /** Freight Instruction */
  freight_instruction: string | null;
  /** Frontoffice Instruction */
  frontoffice_instruction: string | null;
  /** Requires Import Statement */
  requires_import_statement: boolean;
  /** Has Import Statement */
  has_import_statement: boolean;
  /** Has Supplier Invoice */
  has_supplier_invoice: boolean;
  /** Rfp Comment */
  rfp_comment: string | null;
  /** Import Statement Override */
  import_statement_override: boolean;
  /** Override Reason */
  override_reason: string | null;
  /** Sent To Loendersloot On */
  sent_to_loendersloot_on: string | null;
  /** Last Warehouse Message */
  last_warehouse_message: string | null;
  /** Planned Unloading Date */
  planned_unloading_date: string | null;
  /** Expected Delivery Date */
  expected_delivery_date: string | null;
  /** Warehouse Reference */
  warehouse_reference: string | null;
  /** Warehouse Status */
  warehouse_status: string | null;
  /** Number Of Order Lines */
  number_of_order_lines: number;
  /** Number Of Cases */
  number_of_cases: number;
  /** Number Of Attachments */
  number_of_attachments: number;
  /** Number Of Edi Messages */
  number_of_edi_messages: number;
  was_handled_by: User;
  supplier: Fact2ServerApiDiscoverPurchaseOrderPurchaseOrderGetPurchaseOrderResponseSupplier;
  delivery: Delivery | null;
  insurance_type: InsuranceType;
  account: Fact2ServerApiDiscoverPurchaseOrderPurchaseOrderGetPurchaseOrderResponseAccount;
  target_warehouse: Warehouse;
  origin_warehouse: Warehouse | null;
  is_part_of_buy_from_account_sales_order: BuyFromAccountSalesOrder | null;
}

/** GetPurchaseOrdersCollectionViewResponse */
export interface GetPurchaseOrdersCollectionViewResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  /** Warehouse Reference */
  warehouse_reference?: string | null;
  /** Supplier Reference */
  supplier_reference?: string | null;
  /** Expected Delivery Date */
  expected_delivery_date?: string | null;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /**
   * Was Last Updated On
   * @format date-time
   */
  was_last_updated_on: string;
  /** Is Communicated On */
  is_communicated_on?: string | null;
  /** Was Sent To Financial Information System On */
  was_sent_to_financial_information_system_on?: string | null;
  /** Has Attachments */
  has_attachments: boolean;
  /** Incoterm */
  incoterm: string;
  /** Incoterm Location */
  incoterm_location: string;
  status: PurchaseOrderStatus;
  /** Warehouse Status */
  warehouse_status?: string | null;
  /** Supplier Artkey */
  supplier_artkey: number;
  /** Supplier Name */
  supplier_name: string;
  /** Supplier Country Code */
  supplier_country_code: string;
  /** Target Warehouse Name */
  target_warehouse_name: string;
  /** Target Warehouse Artkey */
  target_warehouse_artkey: number;
  /** Purchase Manager Artkey */
  purchase_manager_artkey: number;
  /** Purchase Manager Name */
  purchase_manager_name: string;
  /** Number Of Cases */
  number_of_cases: number;
  /** Was Bought For */
  was_bought_for: string;
  /** Was Bought In */
  was_bought_in: string;
}

/** GetRelatedSalesOrderItemResponse */
export interface GetRelatedSalesOrderItemResponse {
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Sales Order Artkey */
  sales_order_artkey: number;
  /** Sales Order Reference */
  sales_order_reference: string;
  /** Number Of Cases */
  number_of_cases: number;
  /** Case Artkey */
  case_artkey: number;
  /** Number Of Bottles */
  number_of_bottles: number;
  /** Sales Order Account Artkey */
  sales_order_account_artkey: number;
  /** Sales Order Account Name */
  sales_order_account_name: string;
  /** Sales Order Account Slug */
  sales_order_account_slug: string;
  /** Relation Name */
  relation_name: string;
  /** Sales Manager Name */
  sales_manager_name: string;
}

/** GetRelatedSalesOrderTBOItemResponse */
export interface GetRelatedSalesOrderTBOItemResponse {
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Sales Order Artkey */
  sales_order_artkey: number;
  /** Sales Order Reference */
  sales_order_reference: string;
  /** Number Of Cases */
  number_of_cases: number;
  /** Case Artkey */
  case_artkey: number;
  /** Number Of Bottles */
  number_of_bottles: number;
  /** Sales Order Account Artkey */
  sales_order_account_artkey: number;
  /** Sales Order Account Name */
  sales_order_account_name: string;
  /** Sales Order Account Slug */
  sales_order_account_slug: string;
  /** Relation Name */
  relation_name: string;
  /** Sales Manager Name */
  sales_manager_name: string;
  /** Tbo Artkey */
  tbo_artkey: number;
}

/** GetRelationLocationResponse */
export interface GetRelationLocationResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  destination_type: LocationType;
  /** Can Handle Our Stock */
  can_handle_our_stock: boolean;
  /** Street Address */
  street_address: string;
  /** Zip Code */
  zip_code: string;
  /** City */
  city: string;
  /** Country Code */
  country_code: string;
  /** Region Code */
  region_code?: string | null;
  /** Loendersloot Address Id */
  loendersloot_address_id?: number | null;
  /** Emailaddress */
  emailaddress?: string | null;
  /** Excise Id */
  excise_id?: string | null;
  /** Vat Id */
  vat_id?: string | null;
  /** Warehouse Id */
  warehouse_id?: string | null;
  /** Warehouse Base Costs */
  warehouse_base_costs?: string | null;
  /** Warehouse Costs Per Case */
  warehouse_costs_per_case?: string | null;
  /** Currency */
  currency?: string | null;
  /** Is Primary */
  is_primary: boolean;
  /** Is Relations Warehouse */
  is_relations_warehouse: boolean;
}

/** GetRelationPurchaseOrderResponse */
export interface GetRelationPurchaseOrderResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  /** Was Bought In */
  was_bought_in: string;
  /** Is Communicated On */
  is_communicated_on: string | null;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  purchase_order_status: PurchaseOrderStatus;
  /** Incoterm Location */
  incoterm_location: string;
  /** Number Of Cases */
  number_of_cases: number;
  /** Was Bought For */
  was_bought_for: string;
}

/** GetRelationPurchaseOrdersResponse */
export interface GetRelationPurchaseOrdersResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
}

/** GetRelationResponse */
export interface GetRelationResponse {
  /** Street Address */
  street_address: string;
  /** Zip Code */
  zip_code: string;
  /** City */
  city: string;
  /** Country Code */
  country_code: string;
  /** Region Code */
  region_code?: string | null;
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Is Verified */
  is_verified: boolean;
  /**
   * Include Excise In Price
   * @default false
   */
  include_excise_in_price?: boolean;
  /** Currency */
  currency: string;
}

/** GetRelationsResponse */
export interface GetRelationsResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** City */
  city: string;
  /** Country Code */
  country_code: string;
  /** Currency */
  currency: string;
  /**
   * Include Excise In Price
   * @default false
   */
  include_excise_in_price?: boolean;
  /** Is Verified */
  is_verified: boolean;
}

/** GetSPLAuditLogResponse */
export interface GetSPLAuditLogResponse {
  /** Artkey */
  artkey: number;
  type: AuditLogType;
  /** Message */
  message: string;
  /** Spli Artkey */
  spli_artkey: number | null;
  /** Sl Artkey */
  sl_artkey: number | null;
}

/** GetSalesOrderFiguresResponse */
export interface GetSalesOrderFiguresResponse {
  /** Saved Sales Orders */
  saved_sales_orders: number;
  /** Pending Portal Orders */
  pending_portal_orders: number;
  /** Confirmed Sales Orders */
  confirmed_sales_orders: number;
  /** Invoiced Sales Orders */
  invoiced_sales_orders: number;
  /** Total Value */
  total_value: number;
  /** Old Orders */
  old_orders: number;
  /** Average Order Age */
  average_order_age: number;
}

/** GetSalesOrderResponse */
export interface GetSalesOrderResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  /** Origin Artkey */
  origin_artkey: number;
  /** Destination Artkey */
  destination_artkey: number;
  /** Warehouse Instruction */
  warehouse_instruction: string | null;
  /** Freight Instruction */
  freight_instruction: string | null;
  /** Estimated Loading Date */
  estimated_loading_date: string | null;
  /** Incoterm */
  incoterm: string;
  /** Incoterm Location */
  incoterm_location: string;
  /** Supplier Artkey */
  supplier_artkey: number;
  supplier: Fact2ServerApiDiscoverSalesOrderSalesOrderGetSalesOrderResponseSupplier;
  /** Sales Order Status */
  sales_order_status: string;
  /** Was Sold In */
  was_sold_in: string;
  /** Sold Against Rate */
  sold_against_rate: string;
  /**
   * Insurance Type Artkey
   * @default 0
   */
  insurance_type_artkey?: number;
  /** Includes Excise */
  includes_excise: boolean;
  /** Escrow Loendersloot */
  escrow_loendersloot: boolean;
  /** Remark */
  remark: string | null;
}

/** GetSalesOrdersCollectionViewResponse */
export interface GetSalesOrdersCollectionViewResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Sales Order Status */
  sales_order_status: string;
  /** Combined Status */
  combined_status: string;
  /** Incoterm */
  incoterm: string;
  /** Incoterm And Location */
  incoterm_and_location: string;
  /** Supplier Name */
  supplier_name: string;
  /** Destination Name */
  destination_name?: string | null;
  /** Destination Location */
  destination_location?: string | null;
  /** Number Of Confirmed Cases */
  number_of_confirmed_cases: number;
  /** Was Sold For */
  was_sold_for: number;
  /** Was Sold In */
  was_sold_in: string;
  /** Sold Against Rate */
  sold_against_rate: number;
  /** Margin */
  margin: number;
  /** Margin Percentage */
  margin_percentage?: number | null;
  /** Sales Manager Name */
  sales_manager_name: string;
  /** Has Attachments */
  has_attachments: boolean;
  /** Supplier Account Slug */
  supplier_account_slug: string;
  /** Supplier Account Name */
  supplier_account_name: string;
  /** Was Handled By Name */
  was_handled_by_name: string;
  /** Warehouse Status */
  warehouse_status?: string | null;
  /** Warehouse Reference */
  warehouse_reference?: string | null;
  /** Is Complete */
  is_complete: boolean;
  /** Invoice Number */
  invoice_number?: number | null;
  /** Is Invoiced On */
  is_invoiced_on?: string | null;
  delivery_status?: DeliveryStatus | null;
}

/** GetSellableBottlesResponse */
export interface GetSellableBottlesResponse {
  /** Artkey */
  artkey: number;
  /** Volume */
  volume: string;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  refill_status: RefillStatus;
}

/** GetSpecialsPriceListCollectionViewResponse */
export interface GetSpecialsPriceListCollectionViewResponse {
  /** Artkey */
  artkey: number;
  /** Product Name */
  product_name: string;
  /** Product Category Name */
  product_category_name: string;
  /** Case Artkey */
  case_artkey: number;
  /** Case Customs Status */
  case_customs_status: string;
  /** Case Article Code */
  case_article_code: string;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Bottle Gtins */
  bottle_gtins?: string | null;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
  /** Relation Artkey */
  relation_artkey: number;
  /** Tbo Supplier Artkey */
  tbo_supplier_artkey?: number | null;
  /** Tbo Supplier Name */
  tbo_supplier_name?: string | null;
  /** Euro Total Stock Value */
  euro_total_stock_value: string;
  /** Stock Age */
  stock_age: number;
  /** Number Of Cases Available */
  number_of_cases_available: number;
  /** Offer Artkey */
  offer_artkey: number;
  /** Offer Title */
  offer_title: string;
  /** Offer End Date */
  offer_end_date?: string | null;
  /**
   * Offer Start Date
   * @format date-time
   */
  offer_start_date: string;
}

/** GetSpliThroughputResponse */
export interface GetSpliThroughputResponse {
  /** Artkey */
  artkey: number;
  /** Bought Last Month */
  bought_last_month: number;
  /** Bought Last Quarter */
  bought_last_quarter: number;
  /** Bought Last Year */
  bought_last_year: number;
  /** Sold Last Month */
  sold_last_month: number;
  /** Sold Last Quarter */
  sold_last_quarter: number;
  /** Sold Last Year */
  sold_last_year: number;
}

/** GetSplisByBottle */
export interface GetSplisByBottle {
  /** Artkey */
  artkey: number;
  /** Supplier Price List Artkey */
  supplier_price_list_artkey: number;
  /** File Name */
  file_name: string | null;
  /** Supplier Artkey */
  supplier_artkey: number;
  /** Supplier Name */
  supplier_name: string;
  /** Supplier Company Type */
  supplier_company_type: string | null;
  /** Gift Box Type */
  gift_box_type: string;
  /** Number Of Bottles Per Case */
  number_of_bottles_per_case: number;
  /** Price Per Bottle */
  price_per_bottle: string | null;
  /** Price Per Case */
  price_per_case: string | null;
  /** Currency */
  currency: string;
  /** Volume */
  volume: string;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  /** Product Name */
  product_name: string;
  /** Aux Info */
  aux_info: string | null;
}

/** GetSupplierPriceListConfigResponse */
export interface GetSupplierPriceListConfigResponse {
  /** Cache Key */
  cache_key: string;
  /** Column Mapping */
  column_mapping: Record<string, string>;
  /** Header */
  header: string[];
}

/** GetSupplierPriceListItemCollectionResponse */
export interface GetSupplierPriceListItemCollectionResponse {
  /** Artkey */
  artkey: number;
  /** Rank */
  rank?: number | null;
  /** Total */
  total?: number | null;
  /** Score */
  score?: number | null;
  /** Product Artkey */
  product_artkey: number;
  /** Product Name */
  product_name: string;
  /** Product Category Artkey */
  product_category_artkey: number;
  /** Product Category */
  product_category: string;
  /** Number Of Bottles Per Case */
  number_of_bottles_per_case: number;
  /** Cases Per Pallet */
  cases_per_pallet?: number | null;
  /** Cases Per Pallet Layer */
  cases_per_pallet_layer?: number | null;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Volume */
  volume: number;
  /** Alcohol Percentage */
  alcohol_percentage: number;
  /** Refill Status */
  refill_status: string;
  /** Incoterm */
  incoterm: string;
  /** Gift Box Type */
  gift_box_type: string;
  /** Number Of Cases */
  number_of_cases?: number | null;
  /** Number Of Bottles */
  number_of_bottles?: number | null;
  /** Default Country Code */
  default_country_code?: string | null;
  /** Country Of Origin */
  country_of_origin?: string | null;
  /** Currency */
  currency: string;
  /** Euro Price Per Case */
  euro_price_per_case?: string | null;
  /** Euro Price Per Bottle */
  euro_price_per_bottle?: string | null;
  /** Number Of Matching Tbo Items */
  number_of_matching_tbo_items: number;
  /** Price Per Case */
  price_per_case?: string | null;
  /** Price Per Bottle */
  price_per_bottle?: string | null;
  /** Customs Status */
  customs_status: string;
  /** Aux Info */
  aux_info?: string | null;
  /** Is Available */
  is_available: boolean;
  /** Availability Status */
  availability_status?: string | null;
  /** Bottle Gtin Code */
  bottle_gtin_code?: string | null;
  /** Bottle Gtin Codes */
  bottle_gtin_codes?: string | null;
  /** Case Gtin Code */
  case_gtin_code?: string | null;
  /** Line Content */
  line_content: string | null;
  /** Spsl Artkey */
  spsl_artkey?: number | null;
  /** Max Stock Age */
  max_stock_age?: number | null;
  /** Number Of Cases In Stock */
  number_of_cases_in_stock: number;
  /** Number Of Cases In Sales */
  number_of_cases_in_sales: number;
  /** Number Of Cases In Purchase */
  number_of_cases_in_purchase: number;
  /** Number Of Cases Available */
  number_of_cases_available: number;
  /** Avg Purchase Price */
  avg_purchase_price?: string | null;
}

/** GetSupplierPriceListStatisticsResponse */
export interface GetSupplierPriceListStatisticsResponse {
  /** Spl Artkey */
  spl_artkey: number;
  /** Total Items */
  total_items: number;
  /** Total Unresolved */
  total_unresolved: number;
}

/** GetUnresolvedItemsResponse */
export interface GetUnresolvedItemsResponse {
  /** Artkey */
  artkey: number;
  /** Customs Status */
  customs_status: string;
  /** Line Content */
  line_content: string;
  /** Product Name */
  product_name: string | null;
  /** Raw Product Name */
  raw_product_name: string | null;
  /** Volume */
  volume: string | null;
  /** Alcohol Percentage */
  alcohol_percentage: string | null;
  /** Refill Status */
  refill_status: string | null;
  /** Gift Box Type */
  gift_box_type: string | null;
  /** Aux Info */
  aux_info: string | null;
  /** Number Of Bottles */
  number_of_bottles: number | null;
  /** Number Of Cases */
  number_of_cases: number | null;
  /** Number Of Bottles Per Case */
  number_of_bottles_per_case: number | null;
  /** Cases Per Pallet */
  cases_per_pallet: number | null;
  /** Price Per Bottle */
  price_per_bottle: string | null;
  /** Price Per Case */
  price_per_case: string | null;
  /** Currency */
  currency: string | null;
  /** Country Of Origin */
  country_of_origin: string | null;
  /** Availability Status */
  availability_status: string | null;
  /** Availability Date */
  availability_date: string | null;
  /** Matching Score */
  matching_score: string | null;
  /** Is Suppressed */
  is_suppressed: boolean;
}

/** GetUserDownloadLinkResponse */
export interface GetUserDownloadLinkResponse {
  /** Link */
  link: string;
  /**
   * Valid Until
   * @format date-time
   */
  valid_until: string;
}

/** GetUserDownloadsResponse */
export interface GetUserDownloadsResponse {
  /** Artkey */
  artkey: number;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  status: UserDownloadStatus;
  /** File Name */
  file_name: string;
  /** File Size */
  file_size?: number | null;
  /** Warning Message */
  warning_message?: string | null;
  /** Failure Reason */
  failure_reason?: string | null;
}

/** GetVatRateResponse */
export interface GetVatRateResponse {
  /** Artkey */
  artkey: number;
  /** Country Code */
  country_code: string;
  /** Percentage */
  percentage: string;
}

/** GetVoucherPromotionRelationResponse */
export interface GetVoucherPromotionRelationResponse {
  /** Relation Artkey */
  relation_artkey: number;
  /** Relation Name */
  relation_name: string;
  /** Voucher Promotion Artkey */
  voucher_promotion_artkey: number;
  /** Used */
  used: boolean;
  /** Code */
  code: string;
}

/** GetVoucherPromotionResponse */
export interface GetVoucherPromotionResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  voucher_value_type: VoucherValueType;
  /** Value */
  value: string;
  /** Code */
  code: string;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /**
   * Start Date
   * @format date-time
   */
  start_date: string;
  /**
   * End Date
   * @format date-time
   */
  end_date: string;
  /** Account Artkey */
  account_artkey: number;
  /** Account Slug */
  account_slug: string;
}

/** GetVoucherRelationCollectionViewResult */
export interface GetVoucherRelationCollectionViewResult {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Country Code */
  country_code: string;
  /** City */
  city: string;
  /** Sales Manager Name */
  sales_manager_name: string;
  /** Sales Manager Artkey */
  sales_manager_artkey: number;
  /** Is In Voucher Promotion */
  is_in_voucher_promotion: boolean;
}

/** GiftBoxType */
export enum GiftBoxType {
  GB = "GB",
  GBGlassEs = "GB + Glass(es)",
  ValueGlassEs = "+ Glass(es)",
  GBBag = "GB Bag",
  GiftSet = "Gift Set",
  GiftTin = "Gift Tin",
  WoodenGB = "Wooden GB",
  Cradle = "Cradle",
}

/** HTTPExceptionResponse */
export interface HTTPExceptionResponse {
  /** Status Code */
  status_code: number;
  /** Detail */
  detail?: string | null;
  /** Headers */
  headers?: object | null;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** HiddenSupplier */
export interface HiddenSupplier {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
}

/** InsuranceType */
export interface InsuranceType {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
}

/** ItemAccountResponse */
export interface ItemAccountResponse {
  /** Slug */
  slug: string;
  /** Name */
  name: string;
}

/** ItemBottleResponse */
export interface ItemBottleResponse {
  /** Volume */
  volume: string;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  refill_status: RefillStatus;
  product: ItemProductResponse;
}

/** ItemCaseResponse */
export interface ItemCaseResponse {
  /** Number Of Bottles */
  number_of_bottles: number;
  gift_box_type: GiftBoxType | null;
  /** Tax Label */
  tax_label: string | null;
  /** Customs Status */
  customs_status: string;
  /** Item Tag Artkeys */
  item_tag_artkeys: number[];
  /** Best Before Date */
  best_before_date: string | null;
  bottle: ItemBottleResponse;
}

/** ItemProductResponse */
export interface ItemProductResponse {
  /** Name */
  name: string;
}

/** ItemPurchaseOrderItemResponse */
export interface ItemPurchaseOrderItemResponse {
  /** Was Bought For */
  was_bought_for: string;
  /** Was Bought For Plus Costs */
  was_bought_for_plus_costs: string;
  purchase_order: ItemPurchaseOrderResponse;
}

/** ItemPurchaseOrderResponse */
export interface ItemPurchaseOrderResponse {
  /** Artkey */
  artkey: number;
  /** Reference */
  reference: string;
  /** Was Bought In */
  was_bought_in: string;
}

/** ItemTagCollectionResponse */
export interface ItemTagCollectionResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Abbreviation */
  abbreviation: string;
  /** Category Name */
  category_name: string;
}

/** ItemTagResponse */
export interface ItemTagResponse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Abbreviation */
  abbreviation: string;
  /** Item Tag Category Artkey */
  item_tag_category_artkey: number;
}

/** ItemWarehouseResponse */
export interface ItemWarehouseResponse {
  /** Name */
  name: string | null;
}

/** LeadContactStatus */
export enum LeadContactStatus {
  NOT_YET_APPROACHED = "NOT_YET_APPROACHED",
  APPROACHED = "APPROACHED",
  FINALIZED = "FINALIZED",
}

/** LocationType */
export enum LocationType {
  Destination = "destination",
  Warehouse = "warehouse",
  Seaport = "seaport",
  Airport = "airport",
}

/** LoenderslootCosts */
export interface LoenderslootCosts {
  /** Additional Per Case */
  additional_per_case: number | string;
  /** Exw Handover Costs */
  exw_handover_costs?: number | string | null;
  /** Spirits Costs Per Case */
  spirits_costs_per_case: number | string;
  /** Spirits Minimum Costs */
  spirits_minimum_costs: number | string;
  /** Spirits Minimum Cases */
  spirits_minimum_cases: number;
  /** Spirits Maximum Costs */
  spirits_maximum_costs: number | string;
  /** Spirits Maximum Cases */
  spirits_maximum_cases: number;
  /** Inspection Costs First Item */
  inspection_costs_first_item: number | string;
  /** Inspection Costs Per Item */
  inspection_costs_per_item: number | string;
  /** Has Duty Drawback */
  has_duty_drawback: boolean;
  /** Duty Drawback */
  duty_drawback: number | string;
  /** Duty Drawback Per Case */
  duty_drawback_per_case: number | string;
  /** Non Spirits Costs Per Pallet */
  non_spirits_costs_per_pallet: number | string;
  /** Non Spirits Minimum Costs */
  non_spirits_minimum_costs: number | string;
  /** Non Spirits Minimum Pallets */
  non_spirits_minimum_pallets: number;
}

/** LoenderslootMessageStatus */
export enum LoenderslootMessageStatus {
  Received = "Received",
  Processed = "Processed",
  Failed = "Failed",
}

/** MatchedCandidate */
export interface MatchedCandidate {
  bottle: Bottle;
  /** Gift Box Type */
  gift_box_type: string;
  /** Number Of Bottles Per Case */
  number_of_bottles_per_case: number;
  /** Customs Status */
  customs_status: string;
}

/** Media */
export interface Media {
  /** Data */
  data: string;
  /** Name */
  name?: string | null;
  /** Content Type */
  content_type: string;
}

/** MovePOIBulkRequest */
export interface MovePOIBulkRequest {
  /** Source Poi Artkeys */
  source_poi_artkeys: number[];
  /** Target Purchase Order Artkey */
  target_purchase_order_artkey?: number | null;
}

/** MovePOIRequest */
export interface MovePOIRequest {
  /** Source Poi Artkey */
  source_poi_artkey: number;
  /**
   * Number Of Cases To Move
   * @exclusiveMin 0
   */
  number_of_cases_to_move: number;
  /** Target Purchase Order Artkey */
  target_purchase_order_artkey?: number | null;
}

/** NotificationMessage */
export interface NotificationMessage {
  /** Topicarn */
  TopicArn: string;
  /** Messageid */
  MessageId: string;
  /** Message */
  Message: string;
  /** Timestamp */
  Timestamp: string;
  /** Signatureversion */
  SignatureVersion: string;
  /** Signature */
  Signature: string;
  /** Signingcerturl */
  SigningCertURL: string;
  /** Type */
  Type: "Notification";
  /** Subject */
  Subject?: string | null;
}

/** OfferHistoryResponse */
export interface OfferHistoryResponse {
  /** Artkey */
  artkey: number;
  /** Product Name */
  product_name: string;
  /** Offer Artkey */
  offer_artkey: number;
  /** Offer Title */
  offer_title: string;
  /**
   * Offer Start Date
   * @format date-time
   */
  offer_start_date: string;
  /** Offer End Date */
  offer_end_date: string | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Case Customs Status */
  case_customs_status: string;
  case_gift_box_type: GiftBoxType | null;
  /** Case Tax Label */
  case_tax_label: string | null;
  /** Euro Price Per Case */
  euro_price_per_case: string;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
}

/** OfferHistoryTotalsResponse */
export interface OfferHistoryTotalsResponse {
  /** Count */
  count: number;
}

/** OfferItemFilterType */
export enum OfferItemFilterType {
  NEW_ARRIVALS = "NEW_ARRIVALS",
  FAVORITES = "FAVORITES",
  SPECIALS = "SPECIALS",
  BROWSE_STOCK = "BROWSE_STOCK",
}

/** OfferItemStatistic */
export interface OfferItemStatistic {
  offer_item_type: OfferItemFilterType;
  /** Count */
  count: number;
}

/** OfferItemType */
export enum OfferItemType {
  Tbo = "tbo",
  Stock = "stock",
  Purchase = "purchase",
}

/**
 * OfferType
 * An offer item for a relation can be these values.
 *
 * Note: Can be different than the OfferItemType (or RelationPriceListOfferItemType).
 *     This value is determined on more live data than the RelationPriceList.
 */
export enum OfferType {
  Spotlight = "spotlight",
  Regular = "regular",
  Offer = "offer",
  Tbo = "tbo",
  Purchase = "purchase",
}

/** OkResponse */
export interface OkResponse {
  /** Detail */
  detail: string;
}

/** OrderRatingResponse */
export interface OrderRatingResponse {
  /** Comment */
  comment: string | null;
  /** Rating */
  rating: number;
}

/** PackagingStatistic */
export interface PackagingStatistic {
  /** Name */
  name: string;
  /** Count */
  count: number;
}

/** PalletLayoutResponse */
export interface PalletLayoutResponse {
  /** Cases Per Pallet */
  cases_per_pallet?: number | null;
  /** Cases Per Pallet Layer */
  cases_per_pallet_layer?: number | null;
}

/** ParseCandidatesRequest */
export interface ParseCandidatesRequest {
  /** Cache Key */
  cache_key?: string | null;
  /** Column Mapping */
  column_mapping: Record<string, string>;
  /** Offset */
  offset: number;
  /** Limit */
  limit: number;
}

/** PortalOfferItem */
export interface PortalOfferItem {
  /** Artkey */
  artkey: number;
  /** Related Offer Item Artkey */
  related_offer_item_artkey?: number | null;
  /** Offer Item Type */
  offer_item_type: string;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
  /** Bottle Gtin Codes */
  bottle_gtin_codes: string[];
  /** Case Artkey */
  case_artkey: number;
  /** Case Article Code */
  case_article_code: string;
  /** Case Gift Box Type */
  case_gift_box_type?: string | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Case Tax Label */
  case_tax_label?: string | null;
  /** Item Best Before Date */
  item_best_before_date?: string | null;
  /** Case Customs Status */
  case_customs_status: string;
  /** Item Damages */
  item_damages?: string | null;
  /** Item General Tags */
  item_general_tags?: string | null;
  /** Item Pack Size */
  item_pack_size?: string | null;
  /** Item Packaging */
  item_packaging?: string | null;
  /** Product Category */
  product_category: string;
  /** Product Name */
  product_name: string;
  /** Favourite Artkey */
  favourite_artkey?: number | null;
  /** Expired On */
  expired_on?: string | null;
  /** List Quantity */
  list_quantity: number;
  /** Delivery Period */
  delivery_period?: number | null;
  /** Minimum Quantity */
  minimum_quantity?: number | null;
  /**
   * Entry Date
   * @format date-time
   */
  entry_date: string;
  /** Currency */
  currency: string;
  /** List Price */
  list_price: string;
  /** Original Price Per Case */
  original_price_per_case: string;
  /** Excise Per Case */
  excise_per_case?: string | null;
}

/** PortalOrderItemResponse */
export interface PortalOrderItemResponse {
  /** Artkey */
  artkey: number;
  /** Number Of Cases */
  number_of_cases: number;
  /** Portal Comment */
  portal_comment: string | null;
  /** Vat Percentage */
  vat_percentage: string | null;
  /** Product Name */
  product_name: string;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
  /** Case Artkey */
  case_artkey: number;
  /** Case Article Code */
  case_article_code: string;
  /** Case Tax Label */
  case_tax_label: string | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  case_gift_box_type: GiftBoxType | null;
  /** Case Customs Status */
  case_customs_status: string;
  /** Item Best Before Date */
  item_best_before_date: string | null;
  /** Item Damages */
  item_damages: string | null;
  /** Item General Tags */
  item_general_tags: string | null;
  /** Item Pack Size */
  item_pack_size: string | null;
  /** Item Packaging */
  item_packaging: string | null;
  /** Delivery Period */
  delivery_period: string | null;
  /** Minimum Quantity */
  minimum_quantity: number | null;
  offer_item_type: AvailabilityType;
  /** Offer Item Artkey */
  offer_item_artkey: number | null;
  /** List Price */
  list_price: string | null;
  /** Excise Per Case */
  excise_per_case: string | null;
  /** Vat Total */
  vat_total: string | null;
}

/** PortalRelationStatusResponse */
export interface PortalRelationStatusResponse {
  /** Pricelist Ready */
  pricelist_ready: boolean;
}

/** PortalUser */
export interface PortalUser {
  /** Artkey */
  artkey: number;
  contact_person: ContactPerson;
}

/** PricePreference */
export enum PricePreference {
  Bottle = "bottle",
  Case = "case",
}

/** ProcessSupplierPriceListRequest */
export interface ProcessSupplierPriceListRequest {
  /** Header */
  header: string[];
  /**
   * Store Cpp
   * @default false
   */
  store_cpp?: boolean;
  /**
   * Store Cpl
   * @default false
   */
  store_cpl?: boolean;
}

/** Product */
export interface Product {
  /** Artkey */
  artkey: number | null;
  product_category: Fact2ServerApiDiscoverSupplierPriceListCandidatesVerifiedCandidatesMatchedCandidateBottleProductProductCategory;
  /** Name */
  name: string;
}

/** ProductBottle */
export interface ProductBottle {
  /** Artkey */
  artkey: number;
  /** Volume */
  volume: string;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  refill_status: RefillStatus;
}

/** ProductBottleType */
export enum ProductBottleType {
  LIGHT_GLASS = "LIGHT_GLASS",
  HEAVY_GLASS = "HEAVY_GLASS",
  PET = "PET",
  STONE = "STONE",
  PREMIX_RTD_GLASS = "PREMIX_RTD_GLASS",
  PREMIX_RTD_CAN = "PREMIX_RTD_CAN",
  PREMIX_RTD_PET = "PREMIX_RTD_PET",
}

/** ProductCategoryData */
export interface ProductCategoryData {
  /** Artkey */
  artkey?: number | null;
}

/** ProductData */
export interface ProductData {
  /** Artkey */
  artkey?: number | null;
  /** Name */
  name?: string | null;
  /** Default Country Code */
  default_country_code?: string | null;
  bottle_type?: ProductBottleType | null;
  category?: ProductCategoryData | null;
}

/** ProductPhotosRequest */
export interface ProductPhotosRequest {
  /** Offer Item Artkeys */
  offer_item_artkeys: number[];
}

/** ProductPhotosResponse */
export interface ProductPhotosResponse {
  /** Artkey */
  artkey: number;
  /** Rank */
  rank: number;
  /** Image Location */
  image_location?: string | null;
  /** Thumbnail Location */
  thumbnail_location?: string | null;
}

/** Profile */
export interface Profile {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
}

/** PromotionalOrder */
export interface PromotionalOrder {
  /** Promotion Item Id */
  promotion_item_id: string;
  /** Quantity */
  quantity: number;
}

/** PurchaseHistoryResponse */
export interface PurchaseHistoryResponse {
  /** Product Artkey */
  product_artkey: number;
  /** Product Name */
  product_name: string;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
  /** Case Artkey */
  case_artkey: number;
  /** Case Tax Label */
  case_tax_label: string | null;
  case_gift_box_type: GiftBoxType | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Case Excise Nl */
  case_excise_nl: string;
  /** Supplier Name */
  supplier_name: string;
  /** Purchase Order Item Artkey */
  purchase_order_item_artkey: number;
  /** Case Customs Status */
  case_customs_status: string;
  /** Purchase Order Item Number Of Cases */
  purchase_order_item_number_of_cases: number;
  /** Purchase Order Item Was Bought For */
  purchase_order_item_was_bought_for: string;
  /** Purchase Order Item Remark */
  purchase_order_item_remark: string | null;
  /** Supplier Price List Item Aux Info */
  supplier_price_list_item_aux_info: string | null;
  /** Purchase Order Item Total Was Bought For */
  purchase_order_item_total_was_bought_for: string;
  /**
   * Purchase Order Latest Status Update
   * @format date-time
   */
  purchase_order_latest_status_update: string;
  /** Purchase Order Reference */
  purchase_order_reference: string;
  /** Account Artkey */
  account_artkey: number;
  /** Account Slug */
  account_slug: string;
  /** Account Name */
  account_name: string;
  /** Purchase Order Status */
  purchase_order_status: string;
  /** Purchase Order Was Bought In */
  purchase_order_was_bought_in: string;
  /** Purchase Order Bought Against Rate */
  purchase_order_bought_against_rate: string;
  /** Lots */
  lots: (string | null)[];
  /** References */
  references: string[];
}

/** PurchaseHistoryTotalsResponse */
export interface PurchaseHistoryTotalsResponse {
  /** Total */
  total: string | null;
  /** Count */
  count: number;
}

/** PurchaseOrder */
export interface PurchaseOrder {
  /** Reference */
  reference: string;
}

/** PurchaseOrderItem */
export interface PurchaseOrderItem {
  purchase_order: PurchaseOrder;
  /** Suggested Price Per Case */
  suggested_price_per_case: string | null;
}

/** PurchaseOrderItemRFP */
export interface PurchaseOrderItemRFP {
  /** Artkey */
  artkey: number;
  /** Show Bid Price On Rfp */
  show_bid_price_on_rfp: boolean;
  /** Show As New On Rfp */
  show_as_new_on_rfp: boolean;
}

/** PurchaseOrderStatus */
export enum PurchaseOrderStatus {
  Saved = "Saved",
  Confirmed = "Confirmed",
  Cancelled = "Cancelled",
  OnHold = "On Hold",
  ReadyForFIS = "Ready for FIS",
  StockedAndBooked = "Stocked and Booked",
}

/** RefillStatus */
export enum RefillStatus {
  Nonref = "nonref",
  Ref = "ref",
}

/** RefillStatusStatistic */
export interface RefillStatusStatistic {
  refill_status: RefillStatus;
  /** Count */
  count: number;
}

/** RelationCompanyType */
export enum RelationCompanyType {
  Competitor = "Competitor",
  AgentImporterNL = "Agent / Importer NL",
  AgentImporter = "Agent / Importer",
  Retail = "Retail",
  Wholesale = "Wholesale",
  Trader = "Trader",
  TravelRetailDutyFree = "Travel Retail / Duty Free",
  MSI = "MSI",
  MSP = "MSP",
  LogisticsWarehouse = "Logistics / Warehouse",
  ServicePartner = "Service partner",
  Unknown = "Unknown",
}

/** RelationFilter */
export interface RelationFilter {
  /** Search Terms */
  search_terms: string[];
  /** Buyers */
  buyers: string;
  /** Suppliers */
  suppliers: string;
  /** Operates Online */
  operates_online: string;
  /** Client Status */
  client_status: SupplierClientStatus[];
  /** Company Type */
  company_type: RelationCompanyType[];
  /** Purchase Manager */
  purchase_manager: number[];
  /** Sales Manager */
  sales_manager: number[];
  show_mode?: ShowMode | null;
}

/** RelationPriceListHiddenRule */
export enum RelationPriceListHiddenRule {
  RelationIsSupplier = "Relation is supplier",
  RelationHasADifferentCustomsStatus = "Relation has a different customs status",
  TheOfferItemIsNotYetApproved = "The OfferItem is not yet approved",
  TheItemIsNotYetApproved = "The Item is not yet approved",
  TheOfferItemIsHidden = "The OfferItem is hidden",
  CountryOfTheRelationIsBlockedByTheSupplier = "Country of the relation is blocked by the supplier",
  CountryOfTheRelationIsBlockedByPurchaseOrderItem = "Country of the relation is blocked by PurchaseOrderItem",
  CountryOfTheRelationIsBlockedByItem = "Country of the relation is blocked by Item",
  CountryOfTheRelationIsBlockedByTheProduct = "Country of the relation is blocked by the Product",
  CountryOfTheRelationIsBlockedByTheBrand = "Country of the relation is blocked by the Brand",
  CountryOfTheRelationIsBlockedByTheBrandHolder = "Country of the relation is blocked by the BrandHolder",
  CountryOfTheRelationRequiresAnEUAddressOnTheLabel = "Country of the relation requires an EU address on the label",
  TheRelationIsBlockedByTheSupplier = "The relation is blocked by the supplier",
  TheRelationIsBlockedByThePurchaseOrderItem = "The relation is blocked by the PurchaseOrderItem",
  TheRelationIsBlockedByTheItem = "The relation is blocked by the Item",
  TheRelationIsBlockedByTheProduct = "The relation is blocked by the Product",
  TheRelationIsBlockedByTheBrand = "The relation is blocked by the Brand",
  TheRelationIsBlockedByTheBrandHolder = "The relation is blocked by the BrandHolder",
}

/** ResetPasswordRequest */
export interface ResetPasswordRequest {
  /** Request Id */
  request_id: string;
  /** New Password */
  new_password: string;
}

/** ResetUserOTPRequest */
export interface ResetUserOTPRequest {
  /** User Artkey */
  user_artkey: number;
}

/** RotateDirection */
export enum RotateDirection {
  Left = "left",
  Right = "right",
}

/** RotateProductPhotoRequest */
export interface RotateProductPhotoRequest {
  rotate_direction: RotateDirection;
}

/** RotateProductPhotoResponse */
export interface RotateProductPhotoResponse {
  /** Artkey */
  artkey: number;
  /** S3 Location */
  s3_location: string;
  /** S3 Location Thumbnail */
  s3_location_thumbnail: string;
}

/** SalesHistoryResponse */
export interface SalesHistoryResponse {
  /** Product Artkey */
  product_artkey: number;
  /** Product Name */
  product_name: string;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  /** Bottle Volume */
  bottle_volume: string;
  bottle_refill_status: RefillStatus;
  /** Case Artkey */
  case_artkey: number;
  /** Case Tax Label */
  case_tax_label: string | null;
  case_gift_box_type: GiftBoxType | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Case Customs Status */
  case_customs_status: string;
  /** Case Excise Nl */
  case_excise_nl: string;
  /** Supplier Name */
  supplier_name: string;
  /** Sales Manager Name */
  sales_manager_name: string;
  /** Item Reference */
  item_reference: string;
  /** Item Euro Total Stock Value */
  item_euro_total_stock_value: string;
  /** Item Lot */
  item_lot: string;
  /** Sales Order Item Artkey */
  sales_order_item_artkey: number;
  /** Sales Order Item Euro Was Bought For */
  sales_order_item_euro_was_bought_for: string;
  /** Sales Order Item Number Of Cases */
  sales_order_item_number_of_cases: number;
  /** Sales Order Item Price Per Case Excl Excise */
  sales_order_item_price_per_case_excl_excise: string;
  /** Sales Order Item Price Per Case Incl Excise */
  sales_order_item_price_per_case_incl_excise: string;
  /** Sales Order Item Total Margin */
  sales_order_item_total_margin: string;
  /** Sales Order Item Total Euro Was Bought For */
  sales_order_item_total_euro_was_bought_for: string;
  /** Sales Order Item Total Was Sold For Excl Excise */
  sales_order_item_total_was_sold_for_excl_excise: string;
  /** Sales Order Item Total Was Sold For Incl Excise */
  sales_order_item_total_was_sold_for_incl_excise: string;
  /**
   * Sales Order Latest Status Update
   * @format date-time
   */
  sales_order_latest_status_update: string;
  /** Sales Order Reference */
  sales_order_reference: string;
  /** Sales Order Sales Order Status */
  sales_order_sales_order_status: string;
  /** Sales Order Was Sold In */
  sales_order_was_sold_in: string;
  /** Sales Order Sold Against Rate */
  sales_order_sold_against_rate: string;
  /** Purchase Order Reference */
  purchase_order_reference: string;
  /** Purchase Order Was Bought In */
  purchase_order_was_bought_in: string;
  /** Purchase Order Bought Against Rate */
  purchase_order_bought_against_rate: string;
  /** Account Slug */
  account_slug: string;
  /** Account Name */
  account_name: string;
}

/** SalesHistoryTotalsResponse */
export interface SalesHistoryTotalsResponse {
  /** Total */
  total: string | null;
  /** Margin */
  margin: string | null;
  /** Count */
  count: number;
}

/** SalesOrderAdditionalResponse */
export interface SalesOrderAdditionalResponse {
  /** Artkey */
  artkey: number;
  /** Description */
  description: string;
  /** Quantity */
  quantity: number;
  /** Value Per Quantity */
  value_per_quantity: string;
  /** Price Per Unit */
  price_per_unit: string;
  /** Total */
  total: string;
  /** Basket Total */
  basket_total: string;
  /**
   * The value types for an additional. The members here should always have at least a matching member in
   * VoucherValueType (subclassing enums is not possible).
   */
  value_type: SalesOrderAdditionalValueType;
  /** Vat Percentage */
  vat_percentage: string | null;
  /** Sales Order Additional Type */
  sales_order_additional_type: string;
}

/**
 * SalesOrderAdditionalValueType
 * The value types for an additional. The members here should always have at least a matching member in
 * VoucherValueType (subclassing enums is not possible).
 */
export enum SalesOrderAdditionalValueType {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

/** SalesOrderCreditItemResponse */
export interface SalesOrderCreditItemResponse {
  /** Artkey */
  artkey: number;
  /** Number Of Cases */
  number_of_cases: number;
  /** Original Sales Order Artkey */
  original_sales_order_artkey: number;
  /** Original Was Sold In */
  original_was_sold_in: string;
  /** Original Sold Against Rate */
  original_sold_against_rate: string;
  /** Original Number Of Cases */
  original_number_of_cases: number;
  /** Original Price Per Case */
  original_price_per_case: string;
  /** Original Total Was Sold For */
  original_total_was_sold_for: string;
  /** Original Vat Percentage */
  original_vat_percentage: string | null;
  /** Original Vat Per Case */
  original_vat_per_case: string | null;
  /** Original Vat Total */
  original_vat_total: string | null;
  /** Product Name */
  product_name: string;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Cbs Code */
  bottle_cbs_code: string | null;
  /** Case Artkey */
  case_artkey: number;
  /** Case Article Code */
  case_article_code: string;
  case_gift_box_type: GiftBoxType | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Case Tax Label */
  case_tax_label: string | null;
  /** Item Best Before Date */
  item_best_before_date: string | null;
  /** Case Customs Status */
  case_customs_status: string;
  /** Item Damages */
  item_damages: string | null;
  /** Item General Tags */
  item_general_tags: string | null;
  /** Item Pack Size */
  item_pack_size: string | null;
  /** Item Packaging */
  item_packaging: string | null;
}

/** SalesOrderDetailsResponse */
export interface SalesOrderDetailsResponse {
  /** Artkey */
  artkey: number;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Reference */
  reference: string;
  /** Was Sold In */
  was_sold_in: string;
  /** Was Sold For */
  was_sold_for: string;
  /** Was Sold For Incl Vat */
  was_sold_for_incl_vat: string;
  /** Sold Against Rate */
  sold_against_rate: string;
  /** Vat Total */
  vat_total: string;
  /** Incoterm */
  incoterm: string;
  /** Incoterm Location */
  incoterm_location: string;
  /** Includes Excise */
  includes_excise: boolean;
  /** Is Invoiced On */
  is_invoiced_on: string | null;
  /** Invoice Number */
  invoice_number: number | null;
  /** Is Complete */
  is_complete: boolean;
  /** Requested Delivery Date */
  requested_delivery_date: string | null;
  /** Portal Status */
  portal_status: string;
  /** Additional Percentage Total */
  additional_percentage_total: string;
  /** Number Of Cases */
  number_of_cases: number;
  /** Num Items */
  num_items: number;
  /** Account Slug */
  account_slug: string;
}

/** SalesOrderItemByBottle */
export interface SalesOrderItemByBottle {
  /** Artkey */
  artkey: number;
  /** Price Per Case */
  price_per_case: string;
  /** Excise Per Case */
  excise_per_case: string | null;
  /** Price Per Case Excl Excise */
  price_per_case_excl_excise: string;
  /** Price Per Bottle */
  price_per_bottle: string;
  /** Excise Per Bottle */
  excise_per_bottle: string | null;
  /** Price Per Bottle Excl Excise */
  price_per_bottle_excl_excise: string;
  /** Number Of Cases */
  number_of_cases: number;
  /** Case Artkey */
  case_artkey: number;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Case Excise Nl */
  case_excise_nl: string;
  /** Bottle Excise Nl */
  bottle_excise_nl: string;
  /** Bottle Refill Status */
  bottle_refill_status: string;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  case_gift_box_type: GiftBoxType | null;
  /** Case Tax Label */
  case_tax_label: string | null;
  /** Case Customs Status */
  case_customs_status: string;
  /** Account Slug */
  account_slug: string;
  /** Account Name */
  account_name: string;
  /** Account Informal Name */
  account_informal_name: string;
  /** Supplier Name */
  supplier_name: string;
  /** Profile Name */
  profile_name: string;
  /** Sales Order Artkey */
  sales_order_artkey: number;
  /** Sales Order Reference */
  sales_order_reference: string;
  /**
   * Sales Order Created On
   * @format date-time
   */
  sales_order_created_on: string;
  /** Sales Order Was Sold In */
  sales_order_was_sold_in: string;
  /** Sales Order Sold Against Rate */
  sales_order_sold_against_rate: string;
  /** Sales Order Is Invoiced On */
  sales_order_is_invoiced_on: string | null;
  /** Sales Order Is Confirmed On */
  sales_order_is_confirmed_on: string | null;
  /** Sales Order Status */
  sales_order_status: string;
  /**
   * Sales Order Latest Status Update
   * @format date-time
   */
  sales_order_latest_status_update: string;
}

/** SalesOrderItemResponse */
export interface SalesOrderItemResponse {
  /** Artkey */
  artkey: number;
  /** Number Of Cases */
  number_of_cases: number;
  /** Price Per Case */
  price_per_case: string;
  /** Excise Per Case */
  excise_per_case: string | null;
  /** Total Was Sold For */
  total_was_sold_for: string;
  /** Vat Percentage */
  vat_percentage: string | null;
  /** Vat Per Case */
  vat_per_case: string | null;
  /** Vat Total */
  vat_total: string | null;
  /** Is Shipped */
  is_shipped: boolean;
  /** Portal Comment */
  portal_comment: string | null;
  /** Product Category */
  product_category: string;
  /** Product Name */
  product_name: string;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Bottle Cbs Code */
  bottle_cbs_code: string | null;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
  /** Bottle Volume */
  bottle_volume: string;
  /** Case Artkey */
  case_artkey: number;
  /** Case Article Code */
  case_article_code: string;
  /** Case Tax Label */
  case_tax_label: string | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  case_gift_box_type: GiftBoxType | null;
  /** Case Customs Status */
  case_customs_status: string;
  /** Item Best Before Date */
  item_best_before_date: string | null;
  /** Item Damages */
  item_damages: string | null;
  /** Item General Tags */
  item_general_tags: string | null;
  /** Item Pack Size */
  item_pack_size: string | null;
  /** Item Packaging */
  item_packaging: string | null;
  /** Item Bottle Lot */
  item_bottle_lot: string | null;
  /** Delivery Period */
  delivery_period: string | null;
  /** Credited In Sales Orders */
  credited_in_sales_orders: number[] | null;
  offer_item_type: AvailabilityType;
}

/** SalesOrderItemsForItemResponse */
export interface SalesOrderItemsForItemResponse {
  /** Artkey */
  artkey: number;
  /** Sales Order Artkey */
  sales_order_artkey: number;
  /** Sales Order Reference */
  sales_order_reference: string;
  /**
   * Sales Order Created On
   * @format date-time
   */
  sales_order_created_on: string;
  /** Sales Order Was Sold In */
  sales_order_was_sold_in: string;
  /** Sales Order Sold Against Rate */
  sales_order_sold_against_rate: string;
  /** Sales Order Incoterm */
  sales_order_incoterm: string;
  sales_order_status: SalesOrderStatus;
  /** Price Per Case Excl Excise */
  price_per_case_excl_excise: string;
  /** Number Of Cases */
  number_of_cases: number;
  case_gift_box_type: GiftBoxType | null;
  /** Case Customs Status */
  case_customs_status: string;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  /** Bottle Refill Status */
  bottle_refill_status: string;
  /** Product Name */
  product_name: string;
  /** Account Slug */
  account_slug: string;
  /** Account Name */
  account_name: string;
  /** Was Handled By Name */
  was_handled_by_name: string;
  /** Supplier Name */
  supplier_name: string;
  /** Destination Name */
  destination_name: string | null;
}

/** SalesOrderPhotoDownloadRequest */
export interface SalesOrderPhotoDownloadRequest {
  /** Include Attachments */
  include_attachments: boolean;
  /** Include Item Photos */
  include_item_photos: boolean;
}

/** SalesOrderRatingResponse */
export interface SalesOrderRatingResponse {
  /** Rating */
  rating: number;
  /** Comment */
  comment: string | null;
}

/** SalesOrderStatus */
export enum SalesOrderStatus {
  Saved = "Saved",
  Confirmed = "Confirmed",
  Invoiced = "Invoiced",
  Cancelled = "Cancelled",
}

/** SalesOrderTBOItemResponse */
export interface SalesOrderTBOItemResponse {
  /** Artkey */
  artkey: number;
  /** Number Of Cases */
  number_of_cases: number;
  /** Price Per Case */
  price_per_case: string;
  /** Total Was Sold For Incl Excise */
  total_was_sold_for_incl_excise: string;
  /** Vat Percentage */
  vat_percentage: string | null;
  /** Vat Total */
  vat_total: string | null;
  /** Status */
  status: string;
  /** Expected Delivery Date */
  expected_delivery_date: string | null;
  /** Portal Comment */
  portal_comment: string | null;
  offer_item_type: AvailabilityType;
  /** Delivery Period */
  delivery_period: string | null;
  /** Product Name */
  product_name: string;
  /** Bottle Artkey */
  bottle_artkey: number;
  /** Bottle Alcohol Percentage */
  bottle_alcohol_percentage: string;
  bottle_refill_status: RefillStatus;
  /** Bottle Volume */
  bottle_volume: string;
  /** Bottle Cbs Code */
  bottle_cbs_code: string | null;
  /** Case Artkey */
  case_artkey: number;
  /** Case Article Code */
  case_article_code: string;
  case_gift_box_type: GiftBoxType | null;
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Case Tax Label */
  case_tax_label: string | null;
  /** Case Customs Status */
  case_customs_status: string;
  /** Item Best Before Date */
  item_best_before_date: string | null;
  /** Item Damages */
  item_damages: string | null;
  /** Item General Tags */
  item_general_tags: string | null;
  /** Item Pack Size */
  item_pack_size: string | null;
  /** Item Packaging */
  item_packaging: string | null;
}

/** SalesOrderTurnoverResponse */
export interface SalesOrderTurnoverResponse {
  total_turnover_figures: TurnoverFigures;
  saved_turnover_figures: TurnoverFigures;
  overdue_turnover_figures: TurnoverFigures;
  confirmed_turnover_figures: TurnoverFigures;
  in_progress_turnover_figures: TurnoverFigures;
}

/** SalesPaymentTerm */
export interface SalesPaymentTerm {
  /** Description */
  description: string;
}

/** SearchStatisticsResponse */
export interface SearchStatisticsResponse {
  /** Totals */
  totals: object;
  /** Rows */
  rows: object[];
}

/** SellableItemsByBottleResponse */
export interface SellableItemsByBottleResponse {
  purchase_order_item: PurchaseOrderItem;
  case: Fact2ServerApiDiscoverBottleSellableItemsByBottleSellableItemsByBottleResponseCase;
  /** Artkey */
  artkey: number;
  /** Lot */
  lot: string | null;
  /** Stock Age */
  stock_age: number | null;
  /** Euro Was Bought For */
  euro_was_bought_for: string;
  /** Number Of Cases Available */
  number_of_cases_available: number;
  /** Relation Sales Price */
  relation_sales_price: string | null;
  /** Relation Base Price */
  relation_base_price: string | null;
}

/** SetLeadStatusRequest */
export interface SetLeadStatusRequest {
  /** Lead Artkeys */
  lead_artkeys: number[];
  new_contact_status: LeadContactStatus;
}

/** ShowMode */
export enum ShowMode {
  ONLY_IN_VOUCHER_PROMOTION = "ONLY_IN_VOUCHER_PROMOTION",
  ONLY_IN_NOT_VOUCHER_PROMOTION = "ONLY_IN_NOT_VOUCHER_PROMOTION",
  ALL = "ALL",
}

/** SortDefinition */
export interface SortDefinition {
  default_field: SortField;
  /**
   * Fields
   * @default []
   */
  fields?: Record<string, string>[];
}

/** SortField */
export interface SortField {
  /** Id */
  id: string;
  /** Label */
  label: string;
  order: SortOrder;
}

/** SortOrder */
export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

/** SortProductPhotoRequest */
export interface SortProductPhotoRequest {
  /** Artkeys */
  artkeys: number[];
}

/** StatisticsForFilterResponse */
export interface StatisticsForFilterResponse {
  /** Volume Statistics */
  volume_statistics?: VolumeStatistic[];
  /** Alcohol Statistics */
  alcohol_statistics?: AlcoholStatistic[];
  /** Packaging Statistics */
  packaging_statistics?: PackagingStatistic[];
  /** Category Statistics */
  category_statistics?: CategoryStatistic[];
  /** Availability Statistics */
  availability_statistics?: AvailabilityStatistic[];
  /** Offer Item Statistics */
  offer_item_statistics?: OfferItemStatistic[];
  /** Refill Status Statistics */
  refill_status_statistics?: RefillStatusStatistic[];
}

/** StockItem */
export interface StockItem {
  /** Item Artkey */
  item_artkey: number;
  /** Lot */
  lot: string;
  /** Number Of Cases Available */
  number_of_cases_available: number;
  /** Number Of Cases In Purchase */
  number_of_cases_in_purchase: number;
  /** Number Of Cases In Sales */
  number_of_cases_in_sales: number;
  /** Reference */
  reference: string;
  /** Stock Age In Days */
  stock_age_in_days: number;
  /** Was Bought For Plus Costs */
  was_bought_for_plus_costs: string;
}

/** StockReconciliationResult */
export enum StockReconciliationResult {
  NotFoundInDiscover = "Not found in discover",
  NotFoundInLoendersloot = "Not found in loendersloot",
  AccountIsDifferent = "Account is different",
  NumberOfCasesAreDifferent = "Number of cases are different",
  ArticleCodeIsDifferent = "Article code is different",
  BottleGTINIsDifferent = "Bottle GTIN is different",
}

/** StockValue */
export interface StockValue {
  /** Value */
  value: string;
  /** In Percent */
  in_percent: string;
  /** Number Of Cases */
  number_of_cases: number;
}

/** StockValueResponse */
export interface StockValueResponse {
  total_stock_value: StockValue;
  range_0_90_days: StockValue;
  range_91_180_days: StockValue;
  range_181_365_days: StockValue;
  range_366_730_days: StockValue;
  range_more_than_730_days: StockValue;
}

/** SubscriptionConfirmation */
export interface SubscriptionConfirmation {
  /** Topicarn */
  TopicArn: string;
  /** Messageid */
  MessageId: string;
  /** Message */
  Message: string;
  /** Timestamp */
  Timestamp: string;
  /** Signatureversion */
  SignatureVersion: string;
  /** Signature */
  Signature: string;
  /** Signingcerturl */
  SigningCertURL: string;
  /** Type */
  Type: "SubscriptionConfirmation";
  /** Token */
  Token: string;
  /** Subscribeurl */
  SubscribeURL: string;
}

/** SupplierClientStatus */
export enum SupplierClientStatus {
  Client = "Client",
  Prospect = "Prospect",
  LowPotential = "Low Potential",
  NotActive = "Not Active",
  DoNotApproach = "Do Not Approach",
}

/** SupplierPriceListCollectionResponse */
export interface SupplierPriceListCollectionResponse {
  /** Artkey */
  artkey: number;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /**
   * Start Date
   * @format date-time
   */
  start_date: string;
  /** End Date */
  end_date?: string | null;
  /** Due Date */
  due_date?: string | null;
  /** Is Current */
  is_current: boolean;
  /** Supplier Price List Status */
  supplier_price_list_status: string;
  /** Started Processing On */
  started_processing_on?: string | null;
  /** Failed Processing Message */
  failed_processing_message?: string | null;
  /** File Name */
  file_name?: string | null;
  /** Supplier Price List Description */
  supplier_price_list_description: string;
  /** Supplier Artkey */
  supplier_artkey: number;
  /** Supplier Name */
  supplier_name: string;
  /** Price List Manager Name */
  price_list_manager_name: string;
}

/** SupplierPriceListResponse */
export interface SupplierPriceListResponse {
  /** Artkey */
  artkey: number;
  supplier: Fact2ServerApiDiscoverSupplierPriceListSupplierPriceListSupplierPriceListResponseSupplier;
  /**
   * Created On
   * @format date-time
   */
  created_on: string;
  /** Default Currency */
  default_currency: string;
  /** Default Customs Status */
  default_customs_status: string;
  /** Default Incoterm */
  default_incoterm: string;
  /** Default Incoterm Location */
  default_incoterm_location: string;
  /** Start Date */
  start_date: string | null;
  /** End Date */
  end_date: string | null;
  /** File Name */
  file_name: string | null;
  status: SupplierPriceListStatus;
  /** Supplier Price List Description */
  supplier_price_list_description: string;
  /** Suppressed Source Line Count */
  suppressed_source_line_count: number;
  /** Supplier Price List Items Count */
  supplier_price_list_items_count: number;
  /** Started Processing On */
  started_processing_on: string | null;
  /** Unresolved Source Lines Count */
  unresolved_source_lines_count: number;
  /** Unresolved Expert Source Lines Count */
  unresolved_expert_source_lines_count: number;
  /** Header As List */
  header_as_list: string[];
}

/** SupplierPriceListStatus */
export enum SupplierPriceListStatus {
  Active = "active",
  Expired = "expired",
  NotYetActive = "not_yet_active",
  Processing = "processing",
  FailedProcessing = "failed_processing",
  AlwaysActive = "always_active",
  Deleted = "deleted",
}

/** SupplierPriceListUploadRequest */
export interface SupplierPriceListUploadRequest {
  /** Relation Artkey */
  relation_artkey: number;
  /** File */
  file: any;
  /** File Name */
  file_name: string;
  /**
   * Start Date
   * @format date
   */
  start_date: string;
  /** End Date */
  end_date?: string | null;
  /**
   * Description
   * @default ""
   */
  description?: string;
}

/** SupplierPricelistItemRequest */
export interface SupplierPricelistItemRequest {
  bottle: BottleData;
  /** Source Line Artkey */
  source_line_artkey?: number | null;
  /** Artkey */
  artkey?: number | null;
  /** Bottle Artkey */
  bottle_artkey?: number | null;
  /** Supplier Price List Artkey */
  supplier_price_list_artkey: number;
  /** Gift Box Type */
  gift_box_type: string;
  /** Aux Info */
  aux_info?: string | null;
  /** Number Of Bottles Per Case */
  number_of_bottles_per_case: number;
  /** Number Of Cases */
  number_of_cases?: number | null;
  /** Number Of Bottles */
  number_of_bottles?: number | null;
  /** Price Per Bottle */
  price_per_bottle?: number | string | null;
  /** Price Per Case */
  price_per_case?: number | string | null;
  /** Currency */
  currency: string;
  /** Bottle Gtin Code */
  bottle_gtin_code?: string | null;
  /** Case Gtin Code */
  case_gtin_code?: string | null;
  /** Incoterm */
  incoterm: string;
  /** Customs Status */
  customs_status: string;
  /** Availability Status */
  availability_status?: string | null;
  /** Country Of Origin */
  country_of_origin?: string | null;
}

/** SupplierSalesAccount */
export interface SupplierSalesAccount {
  /** Artkey */
  artkey: number;
  /** Slug */
  slug: string;
}

/** SupplierStatistic */
export interface SupplierStatistic {
  /** Name */
  name: string;
  /** Year Values */
  year_values: YearValue[];
}

/** SuppressionListResponse */
export interface SuppressionListResponse {
  /** Artkey */
  artkey: number;
  /** Key */
  key: string;
}

/** SwitchCostStructureRequest */
export interface SwitchCostStructureRequest {
  cost_type: CostType;
}

/** SystemParameterResponse */
export interface SystemParameterResponse {
  /** Name */
  name: string;
  /** Value */
  value: string;
  /** Type */
  type: string;
  /** Description */
  description: string;
}

/** TransferRelationFromOtherAccountRequest */
export interface TransferRelationFromOtherAccountRequest {
  /** Import From Artkey */
  import_from_artkey: number;
}

/** TransferRelationFromOtherAccountResponse */
export interface TransferRelationFromOtherAccountResponse {
  /** Rowcount */
  rowcount: number;
}

/** TurnoverFigures */
export interface TurnoverFigures {
  /** Number Of Orders */
  number_of_orders: number;
  /** Number Of Cases */
  number_of_cases: number;
  /** Total Turnover */
  total_turnover: string;
  /** Avg Margin Percentage */
  avg_margin_percentage: string;
}

/** UpdateItemRequest */
export interface UpdateItemRequest {
  /** Country Of Origin */
  country_of_origin: string;
  /** Cases Per Pallet */
  cases_per_pallet: number | null;
  /** Cases Per Pallet Layer */
  cases_per_pallet_layer: number | null;
  /** Best Before Date */
  best_before_date?: string | null;
  /** Remark */
  remark: string | null;
  /** Bottle Gtin */
  bottle_gtin: string | null;
  /** Case Gtin */
  case_gtin: string | null;
  /** Bottle Lot */
  bottle_lot: string | null;
  /** Item Tag Artkeys */
  item_tag_artkeys: number[];
}

/** UpdateOfferItemRequest */
export interface UpdateOfferItemRequest {
  /** Price Per Case */
  price_per_case: number | string;
  /** Delivery Period In Weeks */
  delivery_period_in_weeks?: number | null;
  /** Maximum Order Quantity */
  maximum_order_quantity?: number | null;
  /** Minimum Order Quantity */
  minimum_order_quantity?: number | null;
  /**
   * Silent Price Change
   * @default false
   */
  silent_price_change?: boolean;
  /** Is Hidden */
  is_hidden: boolean;
  /** Needs Approval */
  needs_approval: boolean;
}

/** UpdateOrCreatePurchaseOrderRequest */
export interface UpdateOrCreatePurchaseOrderRequest {
  /** Relation Artkey */
  relation_artkey: number;
  /** Target Warehouse Artkey */
  target_warehouse_artkey: number;
  /** Origin Warehouse Artkey */
  origin_warehouse_artkey: number;
  /** Incoterm */
  incoterm: string;
  /** Incoterm Location */
  incoterm_location: string;
  /** Currency */
  currency: string;
  /** Exchange Rate */
  exchange_rate: number | string;
  /** Insurance Type Artkey */
  insurance_type_artkey: number;
  /** Supplier Reference */
  supplier_reference?: string | null;
  /** Expected Delivery Date */
  expected_delivery_date?: string | null;
  /** Number Of Euro Pallets */
  number_of_euro_pallets?: number | null;
  /** Number Of Block Pallets */
  number_of_block_pallets?: number | null;
  /**
   * Import Statement Override
   * @default false
   */
  import_statement_override?: boolean;
  /** Override Reason */
  override_reason?: string | null;
  /** Remark */
  remark?: string | null;
}

/** UpdateOrCreateSupplierPriceListRequest */
export interface UpdateOrCreateSupplierPriceListRequest {
  /** Relation Artkey */
  relation_artkey: number;
  /**
   * Start Date
   * @format date-time
   */
  start_date: string;
  /** End Date */
  end_date: string | null;
  /** Always Active */
  always_active: boolean;
  /**
   * Description
   * @default ""
   */
  description?: string;
  default_customs_status: CustomsStatus;
  /**
   * Default Incoterm
   * @default "EXW"
   */
  default_incoterm?: string;
  /**
   * Default Incoterm Location
   * @default "Roosendaal - Netherlands (Loendersloot)"
   */
  default_incoterm_location?: string;
  /**
   * Default Currency
   * @default "EUR"
   */
  default_currency?: string;
  file_holder?: FileHolder | null;
}

/** UpdatePortalProfileRequest */
export interface UpdatePortalProfileRequest {
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Mobile Phone Number */
  mobile_phone_number?: string | null;
  /** Email Address */
  email_address: string;
  /** Language */
  language: string;
  price_preference: PricePreference;
}

/** UpdateProductPhotoRequest */
export interface UpdateProductPhotoRequest {
  /** Is Internal */
  is_internal: boolean;
}

/** UpdateProductPhotoResponse */
export interface UpdateProductPhotoResponse {
  /** Artkey */
  artkey: number;
  /** Item Artkey */
  item_artkey?: number | null;
  /** Offer Item Artkey */
  offer_item_artkey?: number | null;
  /** File Name */
  file_name: string;
  /** S3 Location */
  s3_location: string;
  /** S3 Location Thumbnail */
  s3_location_thumbnail: string;
  /** Is Internal */
  is_internal: boolean;
}

/** UpdatePurchaseOrderCostsRequest */
export interface UpdatePurchaseOrderCostsRequest {
  loendersloot_costs?: LoenderslootCosts | null;
  /** Diesel Surcharge Percentage */
  diesel_surcharge_percentage: number | string | null;
  /** Transport Costs */
  transport_costs: number | string;
  /** Pallet Costs */
  pallet_costs: number | string;
  /** Other Costs */
  other_costs: number | string;
  /** Waste Fund Costs */
  waste_fund_costs: number | string | null;
  /** Warehouse Base Costs */
  warehouse_base_costs: number | string | null;
  /** Warehouse Costs Per Case */
  warehouse_costs_per_case: number | string | null;
}

/** UpdatePurchaseOrderItemRequest */
export interface UpdatePurchaseOrderItemRequest {
  /** Number Of Cases */
  number_of_cases: number;
  refill_status: RefillStatus;
  /** Alcohol Percentage */
  alcohol_percentage: number | string;
  /** Volume */
  volume: number | string;
  /** Was Bought For */
  was_bought_for: number | string;
  /** Number Of Bottles Per Case */
  number_of_bottles_per_case: number;
  /** Tax Label */
  tax_label: string | null;
  /** Customs Status */
  customs_status: string;
  gift_box_type: GiftBoxType | null;
  /** Suggested Price Per Case */
  suggested_price_per_case: number | string | null;
  /** Remark */
  remark: string | null;
  /** Bottle Gtin Code */
  bottle_gtin_code: string | null;
  /** Case Gtin Code */
  case_gtin_code: string | null;
  /**
   * Serialized Item Tags
   * @default []
   */
  serialized_item_tags?: number[];
  /** Country Of Origin */
  country_of_origin: string | null;
  /** Best Before Date */
  best_before_date: string | null;
  /** Cases Per Pallet */
  cases_per_pallet: number | null;
  /** Cases Per Pallet Layer */
  cases_per_pallet_layer: number | null;
}

/** UpdateRelationVoucherPromotionRequest */
export interface UpdateRelationVoucherPromotionRequest {
  relation_filter?: RelationFilter | null;
  /** Relation Artkeys To Use */
  relation_artkeys_to_use: number[];
  /** Relation Artkeys To Exclude */
  relation_artkeys_to_exclude: number[];
}

/** UpdateRemarkRequest */
export interface UpdateRemarkRequest {
  /** Remark */
  remark?: string | null;
}

/** UpdateRequestForProposalRequest */
export interface UpdateRequestForProposalRequest {
  /** Rfp Comment */
  rfp_comment: string | null;
  /** Item Rfp */
  item_rfp: PurchaseOrderItemRFP[];
}

/** UpdateSalesOrderRequest */
export interface UpdateSalesOrderRequest {
  /** Relation Artkey */
  relation_artkey: number;
  /** Destination Artkey */
  destination_artkey: number;
  /** Origin Artkey */
  origin_artkey: number;
  /** Incoterm */
  incoterm: string;
  /** Incoterm Location */
  incoterm_location: string;
  /** Freight Instruction */
  freight_instruction?: string | null;
  /** Warehouse Instruction */
  warehouse_instruction?: string | null;
  /** Estimated Loading Date */
  estimated_loading_date?: string | null;
  /** Currency */
  currency: string;
  /** Exchange Rate */
  exchange_rate: number | string;
  /** Insurance Type Artkey */
  insurance_type_artkey: number;
  /** Escrow Loendersloot */
  escrow_loendersloot: boolean;
  /** Includes Excise */
  includes_excise: boolean;
  /** Remark */
  remark?: string | null;
}

/** UpdateSystemParameterRequest */
export interface UpdateSystemParameterRequest {
  /** Name */
  name: string;
  /** New Value */
  new_value: string;
  /** New Description */
  new_description: string;
}

/** User */
export interface User {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  profile: Profile;
}

/** UserDownloadStatus */
export enum UserDownloadStatus {
  DONE = "DONE",
  PENDING = "PENDING",
  FAILED = "FAILED",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** VerifiedCandidates */
export interface VerifiedCandidates {
  /** Artkey */
  artkey: number;
  /** Candidate */
  candidate: (string | null)[];
  matched_as: MatchedCandidate | null;
  /** Audit Logs */
  audit_logs: AuditLog[];
}

/** VerifyCandidatesResponse */
export interface VerifyCandidatesResponse {
  /** Cache Key */
  cache_key: string;
  /** Items */
  items: VerifiedCandidates[];
}

/** VerifyOTPRequest */
export interface VerifyOTPRequest {
  /** Otp */
  otp: string;
  /** Pre Auth Token */
  pre_auth_token: string;
}

/** VerifyOTPResponse */
export interface VerifyOTPResponse {
  /** Token */
  token: string;
}

/** VolumeFormatting */
export enum VolumeFormatting {
  Cl = "cl",
  L = "l",
  Ml = "ml",
}

/** VolumeStatistic */
export interface VolumeStatistic {
  /** Size In Cl */
  size_in_cl: number;
  /** Count */
  count: number;
}

/** VoucherValueType */
export enum VoucherValueType {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

/** Warehouse */
export interface Warehouse {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Street Address */
  street_address: string;
  /** Zip Code */
  zip_code: string;
  /** City */
  city: string;
  /** Country Code */
  country_code: string;
  /** Warehouse Id */
  warehouse_id: string | null;
  /** Excise Id */
  excise_id: string | null;
}

/** YearValue */
export interface YearValue {
  /** Year */
  year: number;
  /** Value */
  value: string;
}

/** Case */
export interface Fact2ServerApiDiscoverBottleSellableItemsByBottleSellableItemsByBottleResponseCase {
  /** Number Of Bottles */
  number_of_bottles: number;
  /** Tax Label */
  tax_label: string | null;
  gift_box_type: GiftBoxType | null;
  /** Customs Status */
  customs_status: string;
  /** No Eu Address */
  no_eu_address: boolean;
}

/** Case */
export interface Fact2ServerApiDiscoverExploreCaseAndSupplierResponseCase {
  /** Case Number Of Bottles */
  case_number_of_bottles: number;
  /** Tax Label */
  tax_label: string | null;
  gift_box_type: GiftBoxType | null;
  /** Product Artkey */
  product_artkey: number;
  /** Name */
  name: string;
  /** Alcohol Percentage */
  alcohol_percentage: string;
  /** Volume */
  volume: string;
  refill_status: RefillStatus;
}

/** Supplier */
export interface Fact2ServerApiDiscoverExploreCaseAndSupplierResponseSupplier {
  /** Name */
  name: string;
  /** Country Code */
  country_code: string;
  /** Artkey */
  artkey: number;
}

/** ProductCategory */
export interface Fact2ServerApiDiscoverProductProductGetProductsResponseProductCategory {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
}

/** Category */
export interface Fact2ServerApiDiscoverProductCategoryProductCategoryGetProductCategoriesResponseCategory {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Priority */
  priority?: number | null;
  /** Children */
  children: Fact2ServerApiDiscoverProductCategoryProductCategoryGetProductCategoriesResponseCategory[];
}

/** Account */
export interface Fact2ServerApiDiscoverPurchaseOrderPurchaseOrderGetPurchaseOrderResponseAccount {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Slug */
  slug: string;
  /** Informal Name */
  informal_name: string;
  /** Address */
  address: string;
  /** Zip Code */
  zip_code: string;
  /** City */
  city: string;
  /** Country Code */
  country_code: string;
  /** Telephone Number */
  telephone_number: string;
  /** Emailaddress */
  emailaddress: string;
  /** Website Url */
  website_url: string;
  /** Vat Id */
  vat_id: string;
  /** Chamber Of Commerce Number */
  chamber_of_commerce_number: string;
}

/** Account */
export interface Fact2ServerApiDiscoverPurchaseOrderPurchaseOrderGetPurchaseOrderResponseBuyFromAccountSalesOrderAccount {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Slug */
  slug: string;
}

/** Supplier */
export interface Fact2ServerApiDiscoverPurchaseOrderPurchaseOrderGetPurchaseOrderResponseSupplier {
  /** Artkey */
  artkey: number;
  /** Relation Nr */
  relation_nr: number;
  /** Name */
  name: string;
  /** Memo */
  memo: string | null;
  /** Street Address */
  street_address: string;
  /** Zip Code */
  zip_code: string;
  /** City */
  city: string;
  /** Country Code */
  country_code: string;
  purchase_manager: User;
  /** Number Of Countries Hidden */
  number_of_countries_hidden: number;
  /** Number Of Hide From Pricelist For Suppliers */
  number_of_hide_from_pricelist_for_suppliers: number;
  /** Signed Trademark Declaration Valid Until */
  signed_trademark_declaration_valid_until: string | null;
  /** Has Signed Trademark Declaration */
  has_signed_trademark_declaration: boolean;
  /** Has Valid Lexis Nexis */
  has_valid_lexis_nexis: boolean;
  /** Is Verified */
  is_verified: boolean;
  price_preference: PricePreference;
  /** Emailaddress Rfp Documents */
  emailaddress_rfp_documents: string | null;
}

/** Supplier */
export interface Fact2ServerApiDiscoverSalesOrderSalesOrderGetSalesOrderResponseSupplier {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
}

/** ProductCategory */
export interface Fact2ServerApiDiscoverSupplierPriceListCandidatesVerifiedCandidatesMatchedCandidateBottleProductProductCategory {
  /** Name */
  name: string;
}

/** Supplier */
export interface Fact2ServerApiDiscoverSupplierPriceListSupplierPriceListSupplierPriceListResponseSupplier {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  company_type?: RelationCompanyType | null;
  sales_account: SupplierSalesAccount;
}

/** Category */
export interface Fact2ServerApiPortalMenuCategoriesResponseCategory {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Priority */
  priority?: number | null;
  /** Children */
  children: Fact2ServerApiPortalMenuCategoriesResponseCategory[];
  /** Number Of Products */
  number_of_products: number;
}

/** Supplier */
export interface Fact2ServerApiPortalOrderAdditionalDetailsAdditionalSalesOrderDetailsResponseSupplier {
  /** Artkey */
  artkey: number;
  /** Name */
  name: string;
  /** Street Address */
  street_address: string;
  /** Zip Code */
  zip_code: string;
  /** City */
  city: string;
  /** Country Code */
  country_code: string;
  /** Relation Nr */
  relation_nr: number;
  sales_payment_term?: SalesPaymentTerm | null;
}
