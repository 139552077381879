import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

export class CellDestination extends MithrilTsxComponent<any> {
    view(vnode:m.Vnode<any>) {
        return <div className="c-cell-destination td-group">
            <div className="header-subtle">
                {vnode.attrs.row.incoterm}

            </div>
            <span className="details">
                {(() => {
                    if (vnode.attrs.row.destination_name) {
                        return vnode.attrs.row.destination_name
                    }
                    else if (vnode.attrs.row.destination_location) {
                        return vnode.attrs.row.destination_location
                    }
                    return '-'
                })()}
            </span>
            {vnode.attrs.row.warehouse_status && <span className="details">
                {`${vnode.attrs.row.warehouse_status} ${ vnode.attrs.row.warehouse_reference ? vnode.attrs.row.warehouse_reference : ''}`}
            </span>}
        </div>
    }
}
